import React, { useState, useContext } from "react";
import axios from "axios";
import { AuthContext } from "../../context/AuthContext";
import {Button, Form, Icon, InputText, Modal, Select, Tooltip} from "../../UI";
import {encodebody, getDecodedBody} from "../../utils/utils.js";
import tooltips from '../../tooltips/tooltips';
import HelpCenterIcon from '@mui/icons-material/HelpCenter';

function Modalinvite({ clientNr, explorerId, open, onClose }) {
  const { user } = useContext(AuthContext);
  const [selectedEmail, setSelectedEmail] = useState("");
  const [selectedAuth, setSelectedAuth] = useState("reader");

  const handleEmailChange = (event) => {
    setSelectedEmail(event.target.value);
  };

  const handleAuthChange = (event) => {
    setSelectedAuth(event.target.value);
  };

  const handleSave = async (e) => {
    e.preventDefault();

    if (selectedEmail) {
      let explorers = [] ;
      const myExplorer = {name: user.explorerId, designer: (selectedAuth == "designer"), owner: (selectedAuth == "owner"), reader: (selectedAuth == "reader"),}
      explorers.push(myExplorer);
      await handleSendEmail(user.chatbotKey, selectedEmail,explorers);
    }
    onClose();
  };

  async function handleSendEmail(chatbotKey, email, explorers) {
   
    try {
      const clientResponse = await axios.post(`${process.env.REACT_APP_CENTRAL_BACK}/clients/query`, encodebody({clientNr: clientNr}));
      const mypayload = {
        clientNr: clientNr,
        explorers: explorers,
        chatbotKey: chatbotKey,
        toEmail: email,
        url: process.env.REACT_APP_FROND_END + "/acceptinvite",
        client: clientResponse.data,
      };

      const response = await axios.post(process.env.REACT_APP_CENTRAL_BACK + "/invitation/invite", encodebody(mypayload));
      alert("Invite was successfully sent.")
      return true;
    } catch (error) {

      if (error.response) {
        // The request was made and the server responded with a status code that is not in the range of 2xx

        alert(`Failed to send invite to the user: ${getDecodedBody(error.response.data)}`);
        return false
      } else if (error.request) {
        // The request was made but no response was received
        alert("Failed to send invite to the user: No response from server");
        return false
      } else {
        // Something happened in setting up the request that triggered an Error
        alert(`Failed to send invite to the user: ${getDecodedBody(error.message)}`);
        return false
      }
    }
  };

  return (
    <Modal open={open} onClose={onClose} maxWidth="48rem">
      <Modal.Title>
        Send Invitation
        <Tooltip tooltip={tooltips.invitation} placement="right" trigger="click" interactive={true}>
          <Icon interactive={true} size="sm">
            <HelpCenterIcon />
          </Icon>
        </Tooltip>
      </Modal.Title>
      <Modal.Body>
        <Form onSubmit={handleSave}>
          <Form.Control>
            <Form.Label htmlFor="workflowName">Email</Form.Label>
            <InputText
              type="email"
              id="workflowName"
              value={selectedEmail}
              onChange={handleEmailChange}
            />
          </Form.Control>
          <Form.Control>
            <Form.Label htmlFor="userAuth">User Authorization</Form.Label>
            <Select
              id="userAuth"
              value={selectedAuth}
              onChange={handleAuthChange}
              options={["Designer", "Owner", "Reader"].map(role => ({label: role, value: role.toLowerCase()}))}
              />
          </Form.Control>
          <Form.Row>
            <Button color="secondary" onClick={onClose}>Cancel</Button>
            <Button type="submit">Send Invitation</Button>
          </Form.Row>
        </Form>
      </Modal.Body>
    </Modal>
  );
}

export default Modalinvite;
