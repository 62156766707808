import React, { useState, useEffect } from 'react';
import axios from "axios";
import { Form, Icon, InputText, TextEditor, Toolbar } from '../../UI';
import HelpCenterIcon from '@mui/icons-material/HelpCenter';
import {encodebody, getDecodedBody} from "../../utils/utils.js";

function Productcomplianceview({ clientNr, explorerId, productName, authorization, updateTreeView }) {
  const [product, setProduct] = useState(null);

  const handleComplianceDescriptionChange = (value) => {
    setProduct((prevProduct) => ({
      ...prevProduct,
      complianceDescription: value,
    }));
  };

  const handleUpdate = async () => {
    const apiUrl = process.env.REACT_APP_CENTRAL_BACK + '/product/update';

    // Define the request body
    const requestBody = {
      clientNr: clientNr,
      explorerId: explorerId,

      productName:product.productName,
      complianceDescription: product.complianceDescription
    };

      const myResponse = await axios.post(apiUrl, encodebody(requestBody));
      alert("Product was succesfully updated.");

  };

  useEffect(() => {
    const fetchProduct = async () => {
      const apiUrl = `${process.env.REACT_APP_CENTRAL_BACK}/product/query`;

      // Define the request body
      const requestBody = {
        clientNr,
        explorerId,
        productName,
      };

      try {
        // Make a POST request to fetch the product
        const response = await axios.post(apiUrl, encodebody(requestBody), {
          headers: {
            'Content-Type': 'application/json',
          },
        });

        const data = getDecodedBody(response.data);
        // Set the fetched product data to the state
        setProduct(data);
      } catch (error) {
        console.error('Error fetching product:', error);
      }
    };

    fetchProduct();
  }, [clientNr, explorerId, productName]); // Dependency array ensures the effect runs when these variables change

  return ( product
      ? (
          <>
            <header className="section-right__toolbar-container">
              <Toolbar>
                {(authorization.designer || authorization.owner) && (
                  <Toolbar.Button onClick={handleUpdate}>Update</Toolbar.Button>
                )}
                <Toolbar.Item>
                  <Icon href="https://wiki.gwocu.com/en/GWOCU-Studio/products-detail-panel#productcompliance-section">
                    <HelpCenterIcon />
                  </Icon>
                </Toolbar.Item>
              </Toolbar>
            </header>
            <div className="section-right__content">
              <Form className="section-right__form">
                <Form.Control>
                  <Form.Label htmlFor="productName">Product Name</Form.Label>
                  <InputText
                    id="productName"
                    value={product.productName}
                    disabled
                  />
                </Form.Control>
                <Form.Control>
                  <Form.Label htmlFor="complianceDescription">Compliance Description</Form.Label>
                  <TextEditor
                    value={product.complianceDescription}
                    onChange={handleComplianceDescriptionChange}
                    readOnly={!authorization.designer && !authorization.owner}
                    />
                </Form.Control>
              </Form>
            </div>
          </>
      ) : (
        <p>Loading product information...</p>
      )
  );
}

export default Productcomplianceview;
