import React, { useContext, useEffect, useState } from "react";
import axios from "axios";
import { AuthContext } from "../../context/AuthContext.js";
import { Button, Icon, Menu, Tooltip } from "../../UI";
import { encodebody, getDecodedBody } from "../../utils/utils.js";
import tooltips from "../../tooltips/tooltips.js";
import {
  BookmarkBorderOutlined,
  Code,
  CreateNewFolderOutlined,
  DeleteOutline,
  DescriptionOutlined,
  EditOutlined,
  EmailOutlined,
  FolderOutlined,
  GroupAddOutlined,
  GroupOutlined,
  MoreVertOutlined,
  SyncOutlined,
} from "@material-ui/icons";
import HelpCenterIcon from "@mui/icons-material/HelpCenter";
import "./treemenu.css";

const TreeMenu = ({ onSelectTreeMenuItem, refetching, finishRefetching }) => {
  const [explorers, setExplorers] = useState([]);
  const [listExplorers, setListExplorers] = useState([]);
  const [invites, setInvites] = useState([]);
  const [users, setUsers] = useState([]);
  const { user } = useContext(AuthContext);

  function filterUsersByExplorerId(users, explorerId) {
    return users.filter((user) =>
      user.explorers.some((explorer) => explorer.name === explorerId)
    );
  }

  useEffect(() => {
    if(!refetching) return;

    const fetchData = async () => {
      try {
        const response = await axios.post(
          process.env.REACT_APP_CENTRAL_BACK + "/users/query",
          encodebody({
            clientNr: user.clientNr,
            chatbotKey: user.chatbotKey,
            email: user.email,
          })
        );
        //set workspaces
        const listExplorers = getDecodedBody(response.data.explorers);
        const names = listExplorers.map((explorer) => explorer.name);
       
        setExplorers(names); // Adjust according to your response structure
        // fetch invites to populate submenu
        setListExplorers(listExplorers);
        const inviteResponse = await axios.post(
          process.env.REACT_APP_CENTRAL_BACK + "/invitation/queryall",
          encodebody({
            chatbotKey: user.chatbotKey,
          })
        );
        const inviteResponseData = getDecodedBody(inviteResponse.data);
        const emails = inviteResponseData.map((invite) => invite.email);
        setInvites(emails); // Set the state with the array of emails

        const usersResponse = await axios.post(
          process.env.REACT_APP_CENTRAL_BACK + "/users/queryall",
          encodebody({
            clientNr: user.clientNr,
            chatbotKey: user.chatbotKey,
          })
        );
        // now filter the users so that the list will include only those tha are
        // member of this workspace

        const allUsers = getDecodedBody(usersResponse.data);
        const filteredUsers = filterUsersByExplorerId(
          allUsers,
          user.explorerId
        );

        setUsers(filteredUsers); // Set the state with the array of emails
      } catch (error) {
        console.error("Failed to fetch data:", error);
      }
    };

    fetchData().then(() => finishRefetching())
  }, [refetching]); // The empty array ensures this effect runs only once after the component mounts

  const handleClick =
    (item, value = null) =>
    async () => {
      await onSelectTreeMenuItem(item, value);
    };

  const handleDeleteExplorer = (item, value) => async (e) => {
    e.stopPropagation();
    if (
      window.confirm(
        `Are you sure you want to delete ${value}? Deletion of a workspace is a very destructive operation that will delete all products and workflows including associated APIS and is irreversible`
      )
    ) {
      await onSelectTreeMenuItem(item, value);
    } else {
      // User clicked 'Cancel', do nothing
      console.log("Deletion cancelled.");
    }
  };

  const handleDeleteInvite = (item, value) => async () => {
    if (
      window.confirm(
        `Are you sure you want to delete ${value}?. The person invited will no longer be able to join.`
      )
    ) {
      await onSelectTreeMenuItem(item, value);
    } else {
      // User clicked 'Cancel', do nothing
      console.log("Deletion cancelled.");
    }
  };

  const handleDeleteUser = (item, value) => async () => {
    if (
      window.confirm(
        `Are you sure you want to remove ${value}? The user will be removed from this workspace only.`
      )
    ) {
      await onSelectTreeMenuItem(item, value);
    } else {
      // User clicked 'Cancel', do nothing
      console.log("Deletion cancelled.");
    }
  };

  const handleEditUser = (item, value) => async () => {
    await onSelectTreeMenuItem(item, value);
  };

  const handleCreateWorkspace = (item, value) => async (e) => {
    e.stopPropagation();
    const workspaceName = prompt("Please enter the name of the new workspace:");
    if (workspaceName) {
      // Checks if the user entered something and did not just cancel the dialog
      console.log(`Creating workspace with name: ${workspaceName}`);
      await onSelectTreeMenuItem(item, workspaceName);
    } else {
      console.log("Workspace creation cancelled.");
    }
  };

  function IsOwnerExplorer(explorers, name) {
    const explorer = explorers.find((explorer) => explorer.name === name);
    return explorer ? explorer.owner : false;
  }

  function IsOwnerOrDesignerExplorer(explorers, name) {
    const explorer = explorers.find((explorer) => explorer.name === name);
    return explorer ? explorer.designer || explorer.owner : false;
  }

  function hasOwnerOrDesigner(explorers) {
    return explorers.some((explorer) => explorer.owner || explorer.designer);
  }

  return (
    <Menu>
      <Menu.Handler>
        <Tooltip tooltip={tooltips.mainMenu} placement="right">
          <Button color="secondary" rounded={true} size="xs" >
            <MoreVertOutlined />
          </Button>
        </Tooltip>
      </Menu.Handler>
      <Menu.List>
        {IsOwnerExplorer(listExplorers, user.explorerId) && (
          <>
            <Menu.NestedMenu>
              <Menu.NestedMenuHandler
                onClick={handleClick("invitations")}
              >
                <GroupAddOutlined />
                <span>Invitations</span>
              </Menu.NestedMenuHandler>
              <Menu.List>
                <Menu.Item onClick={handleClick("SendNewInvitation")}>
                  Send private invitation
                </Menu.Item>
                <Menu.Item
                  onClick={handleClick("CreatePublicInvitation")}
                >
                  Create public invitation
                </Menu.Item>
                <Menu.Divider />
                <Menu.Item type="generic" centered className="menu-tree__heading">
                  Invitations Pending
                </Menu.Item>
                {invites.map((invite) => (
                  <React.Fragment key={`invite-${invite}`}>
                    <Menu.Divider />
                    <Menu.Item type="generic">
                      <EmailOutlined />
                      {invite}
                      <div className="menu-tree__item-button-group">
                        <Tooltip tooltip={{content: "Delete invitation", isHtml: false}}>
                          <Button
                            color="danger"
                            rounded={true}
                            size="xs"
                            onClick={handleDeleteInvite("deleteInvite", invite)}
                          >
                            <DeleteOutline />
                          </Button>
                        </Tooltip>
                      </div>
                    </Menu.Item>
                  </React.Fragment>
                ))}
                <Menu.Divider />
                <Menu.Item href="https://wiki.gwocu.com/en/GWOCU-Studio/product-tree-panel-menu#invitation-section">
                  <Icon size="xs">
                    <HelpCenterIcon />
                  </Icon>
                  More information
                </Menu.Item>
              </Menu.List>
            </Menu.NestedMenu>
            <Menu.NestedMenu>
              <Menu.NestedMenuHandler onClick={handleClick("users")}>
                <GroupOutlined />
                Users
              </Menu.NestedMenuHandler>
              <Menu.List>
                {users.map((user) => (
                  <React.Fragment key={`user-${user.email}`}>
                    <Menu.Item type="generic">
                      <EmailOutlined />
                      <span>{user.email}</span>
                      {user.username !== "Admin" &&
                        user.username !== "public@gwocu.com" && (
                          <div className="menu-tree__item-button-group">
                            <Tooltip tooltip={tooltips.editUser}>
                              <Button
                                color="secondary"
                                rounded={true}
                                size="xs"
                                onClick={handleEditUser("editUser", user.email)}
                              >
                                <EditOutlined />
                              </Button>
                            </Tooltip>
                            <Tooltip tooltip={tooltips.deleteUser}>
                              <Button
                                color="danger"
                                rounded={true}
                                size="xs"
                                onClick={handleDeleteUser(
                                  "deleteUser",
                                  user.email
                                )}
                              >
                                <DeleteOutline />
                              </Button>
                            </Tooltip>
                          </div>
                        )}
                    </Menu.Item>
                    <Menu.Divider />
                  </React.Fragment>
                ))}
                <Menu.Item href="https://wiki.gwocu.com/en/GWOCU-Studio/product-tree-panel-menu#users-section">
                  <Icon size="xs">
                    <HelpCenterIcon />
                  </Icon>
                  More information
                </Menu.Item>
              </Menu.List>
            </Menu.NestedMenu>
            <Menu.Divider />
          </>
        )}
        {IsOwnerOrDesignerExplorer(listExplorers, user.explorerId) && (
          <>
            <Menu.NestedMenu>
              <Menu.NestedMenuHandler
                onClick={handleClick("api-pre-request-actions")}
              >
                <DescriptionOutlined />
                Pre-Request Actions
              </Menu.NestedMenuHandler>
              <Menu.List>
                <Menu.Item onClick={handleClick("workspace-action")}>
                  <Code />
                  Workspace Actions
                </Menu.Item>
                <Menu.Item onClick={handleClick("api-action")}>
                  <Code />
                  API Actions
                </Menu.Item>
                <Menu.Item href="https://wiki.gwocu.com/en/GWOCU-Studio/product-tree-panel-menu#prerequest-section">
                  <Icon size="xs">
                    <HelpCenterIcon />
                  </Icon>
                  More information
                </Menu.Item>
              </Menu.List>
            </Menu.NestedMenu>
            <Menu.Divider />
            <Menu.Item onClick={handleClick("exportproducts")}>
              <FolderOutlined />
              Export Products
            </Menu.Item>
            <Menu.Item onClick={handleClick("importproducts")}>
              <FolderOutlined />
              Import Products
            </Menu.Item>
            <Menu.Divider />
            <Menu.NestedMenu>
              <Menu.NestedMenuHandler
                onClick={handleClick("integrations")}
              >
                <SyncOutlined />
                Integrations
              </Menu.NestedMenuHandler>
              <Menu.List>
                <Menu.Item onClick={handleClick("trainyourchatbots")}>
                  <SyncOutlined />
                  Train your chatbots
                </Menu.Item>
                <Menu.Item
                  onClick={handleClick("importapidefinitions")}
                >
                  <SyncOutlined />
                  Import API Definitions
                </Menu.Item>
                {/* <Menu.Item
                  onClick={handleClick("export-documentation")}
                >
                  <SyncOutlined />
                  Export Documentation
                </Menu.Item> */}
                <Menu.Item href="https://wiki.gwocu.com/en/GWOCU-Studio/product-tree-panel-menu#prerequest-section">
                  <Icon size="xs">
                    <HelpCenterIcon />
                  </Icon>
                  More information
                </Menu.Item>
              </Menu.List>
            </Menu.NestedMenu>
            <Menu.Divider />
          </>
        )}
        <Menu.Item centered href="https://wiki.gwocu.com/en/GWOCU-Studio/product-tree-panel-menu#workspaces-section" className="menu-tree__heading">
          <Icon size="xs" color="secondary">
            <HelpCenterIcon />
          </Icon>
          Workspaces
        </Menu.Item>
        {IsOwnerOrDesignerExplorer(listExplorers, user.explorerId) && (
          <>
            <Menu.Divider />
            <Menu.Item
              onClick={handleCreateWorkspace("createWorkspace")}
            >
              <CreateNewFolderOutlined />
              Create New Workspace
            </Menu.Item>
            <Menu.Divider />
            </>
        )}
        {explorers.map((explorer) => (
          <React.Fragment key={`explorer-${explorer}`}>
            <Menu.Item
              onClick={handleClick("explorer", explorer)}
            >
              <BookmarkBorderOutlined />
              {explorer}
              {explorer !== "1" &&
                IsOwnerExplorer(listExplorers, explorer)
                 && user.explorerId !== explorer
                 &&(
                  <div className="menu-tree__item-button-group">
                    <Tooltip
                      tooltip={tooltips.deleteWorkspace}
                      placement="right"
                    >
                      <Button
                        color="danger"
                        rounded={true}
                        size="xs"
                        onClick={handleDeleteExplorer(
                          "deleteExplorer",
                          explorer
                        )}
                      >
                        <DeleteOutline />
                      </Button>
                    </Tooltip>
                  </div>
                )}
            </Menu.Item>
          </React.Fragment>
        ))}
      </Menu.List>
    </Menu>
  );
};

export default TreeMenu;
