import { ComputerRounded, DiamondRounded, LightModeRounded } from "@mui/icons-material";

// Theme configuration array
export const themes = [
  // The first theme is the default, its stylesheet should be in public/assets
  {
    dataTheme: "default",
    name: "Matrix",
    Icon: ComputerRounded
  },
  // Other theme stylesheets must be located in this directory (src/themes)
  {
    // Ensure dataTheme matches the [data-theme] selector in the stylesheet
    // Stylesheet naming convention: dataTheme-theme.css
    dataTheme: "light",
    // Name of the theme displayed to the user in the navbar menu
    name: "Light",
    // Icon displayed beside the theme name in the navbar menu
    // For visual consistency, choose icons from the same library.
    // Icons should be chosen from the same library for consistency.
    // See options here: https://mui.com/material-ui/material-icons/?theme=Rounded
    Icon: LightModeRounded
  },
  {
    dataTheme: "premium",
    name: "Premium",
    Icon: DiamondRounded
  }
]

// Dynamically loads non-default themes based on the configuration object
const loadThemes = async () => {
  try {
    await Promise.all(
      themes.slice(1).map((theme) =>
        import(`./${theme.dataTheme}-theme.css`).catch((err) => {
          console.error(`Failed to load theme ${theme.id}:`, err);
        })
      )
    )
  } catch (error) {
    console.error('Error loading themes:', error);
  }
};

loadThemes();