import React, { useState, useEffect } from "react";
import axios from "axios";
import {encodebody, getDecodedBody} from "../../utils/utils.js";
import {Button, Form, Modal, Select} from "../../UI";

function Modallink({ clientNr, explorerId, graph, open, onClose }) {
  const [sources, setSources] = useState([]);
  const [targets, setTargets] = useState([]);
  const [selectedSource, setSelectedSource] = useState(null);
  const [selectedTarget, setSelectedTarget] = useState(null);
  const [selectedType, setSelectedType] = useState("STRAIGHT");
  const typeOptions = ["STRAIGHT", "CURVE_SMOOTH", "CURVE_FULL"];

  useEffect(() => {
    if(!open) return;
    const fetchData = async () => {
      const myBody = {
        clientNr: clientNr,
        explorerId: explorerId,
        workflowName: graph.name,
      }
      try {
        const tasksresponse = await axios.post(process.env.REACT_APP_CENTRAL_BACK + "/task/queryall", encodebody(myBody));
        const tasksresponseData = getDecodedBody(tasksresponse.data)
        setSources(tasksresponseData);
        setTargets(tasksresponseData);
        if (tasksresponseData.length > 0) {
          setSelectedSource(tasksresponseData[0].taskId);
        }
        if (tasksresponseData.length > 0) {
          setSelectedTarget(tasksresponseData[0].taskId);
        }

      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, [open]); // Empty dependency array ensures the effect runs only once after the initial render

  const handleSave = async () => {
    // Perform save logic with selectedSource and selectedTarget
    // You can use these values to update your backend or state, as needed
    if (await handlecreateLink(selectedSource, selectedTarget))
    {
      onClose();
    }
  };

  async function handlecreateLink(nodeIdSource, nodeIdtarget)  {
    const currentLinks = graph.links

    const MyNewLinkObject = {
      source: nodeIdSource,
      target: nodeIdtarget,
      type: selectedType
    }

    if (!validNewLink(MyNewLinkObject,currentLinks ))
    {
      alert("link source and target not valid. Please us another combination");
      return false;
    }
    currentLinks.push(MyNewLinkObject);

    const mypayload = {
      clientNr: clientNr,
      explorerId: explorerId,
      workflowName: graph.name,
      links: currentLinks
    };

    

    const response = await axios.post(process.env.REACT_APP_CENTRAL_BACK + "/link/update", encodebody(mypayload));
    
    // setNodesAdded(nodesAdded+1);
    return true;
  };

  function validNewLink(myLinkObject, myLinkList)
  {
    if (myLinkObject.source === myLinkObject.target )
    {
    return false
    }

    if (isObjectInList(myLinkObject,myLinkList))
    {
    return false
    }
    return true;
  }

  const isObjectInList = (obj, list) => {
    return list.some(item => item.source === obj.source && item.target === obj.target);
  };

  return (
    <Modal open={open} onClose={onClose} maxWidth="48rem">
      <Modal.Title>Add new Link</Modal.Title>
      <Modal.Body>
        <Form>
          <Form.Control>
            <Form.Label htmlFor="sources">Source</Form.Label>
            <Select
              id="sources"
              defaultValue={selectedSource}
              onChange={(e) => setSelectedSource(e.target.value)}
              options={sources.map((source)=> ({label: source.name, value: source.taskId}))}
            />
          </Form.Control>
          <Form.Control>
            <Form.Label htmlFor="targets">Target</Form.Label>
            <Select
              id="targets"
              defaultValue={selectedTarget}
              onChange={(e) => setSelectedTarget(e.target.value)}
              options={targets.map((target) => ({label: target.name, value: target.taskId}))}
            />
          </Form.Control>
          <Form.Control>
            <Form.Label htmlFor="modalLinkType">Link Type</Form.Label>
              <Select
                id="modalLinkType"
                value={selectedType}
                onChange={(e) => setSelectedType(e.target.value)}
                options={typeOptions.map((type) => ({label: type, value: type}))}
              />
          </Form.Control>
          <Form.Row>
            <Button color="secondary" onClick={onClose}>Cancel</Button>
            <Button onClick={handleSave}>Save</Button>
          </Form.Row>
        </Form>
      </Modal.Body>
    </Modal>
  );
}


export default Modallink;
