import React, { useState, useEffect } from "react"
import { toast } from 'react-toastify'; // import toastify components
import { CopyToClipboard } from "react-copy-to-clipboard";
import axios from "axios";
import { useTheme } from "../../context/ThemeContext.js";
import { SyntaxHighlighter } from "../../UI";
import {generateJavaScriptCode,generatePythonCode, getDecodedBody, encodebody} from "../../utils/utils.js";
import 'react-toastify/dist/ReactToastify.css'; // import toastify CSS

const ApiCode = ({ clientNr, explorerId, productName, workflowName, taskId, apiName, codeType}) => {
  const [explorer, setExplorer] = useState([]);
  const [requestBodyFields, setRequestBodyFields] = useState({});
  const [api, setApi] = useState({});
  const [generatedCode, setGeneratedCode] = useState('');
  const [copied, setCopied] = useState(false);
  const { isADarkTheme } = useTheme()

  const handleCopy = () => {
    setCopied(true);
    toast.success('Code copied!', {autoClose: 1000});
  };

  const handleRequestBodyChange = (field, value) => {
    // Update the state with the new value for the specified field
    setRequestBodyFields((prevFields) => ({
      ...prevFields,
      [field]: value,
    }));
  };

  useEffect(() => {
      fetchApi();
  }, [codeType]);

  const fetchApi = async () => {

    try {
      const myApibody =
      {
        clientNr: clientNr,
        explorerId: explorerId,
        name: apiName
      }
      const response = await axios.post(process.env.REACT_APP_CENTRAL_BACK + "/api/query", encodebody(myApibody));
      const myApi = getDecodedBody(response.data);

      generateCode(myApi)

      setApi(myApi);

      const myExplorerbody = {
        clientNr: clientNr,
        explorerId: explorerId
      }
      const Eresponse = await axios.post(process.env.REACT_APP_CENTRAL_BACK + "/explorer/query", encodebody(myExplorerbody));
      const myExplorer = getDecodedBody(Eresponse.data);
      setExplorer(myExplorer);

      if (myApi.requestBody) {
        const initialRequestBodyFields = { ...myApi.requestBody };
        setRequestBodyFields(initialRequestBodyFields);
      }
    } catch (error) {
      // Handle any errors
      console.error(error);
    }
  };

  const generateCode = (api) => {
    let combinedPythonCode = '# Python Code';
    let combinedJavaScriptCode = '// Javascript Code';

    if (codeType === "python") {
      const pythonCode = generatePythonCode(api, explorer, requestBodyFields);
      combinedPythonCode += pythonCode;
    } else if (codeType === "javascript") {
      const javascriptCode = generateJavaScriptCode(api, explorer, requestBodyFields);
      combinedJavaScriptCode += javascriptCode;
    }

    // Determine the combined code based on the selected codeType
    const combinedCode = codeType === "python" ? combinedPythonCode : combinedJavaScriptCode;

    // Set the combined code using setGeneratedCode
    setGeneratedCode(combinedCode);
  };

  return (
    <>
      <div className="section-right__toolbar-container section-right__toolbar-container--empty" />
      <div className="section-right__content">
        <div className="section-right__syntax-highlighter">
          <SyntaxHighlighter code={generatedCode} darkMode={isADarkTheme} />
        </div>
      </div>
    </>
    )
};

export default ApiCode;
