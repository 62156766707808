import React, { useState, useEffect, useContext } from "react";
import axios from "axios";
import { AuthContext } from "../../context/AuthContext";
import { Button, Form, InputText, Modal } from "../../UI";
import { encodebody, getDecodedBody } from "../../utils/utils.js";

function Modalacceptpublicinvite() {
  

  const { user } = useContext(AuthContext);
  const [email, setEmail] = useState('');
  const [client, setClient] = useState('');
  const [publicUser, setPublicUser] = useState({});
  const params = new URLSearchParams(window.location.search);
  const token = params.get('token');

  useEffect(() => {
    const verifyToken = async () => {
      try {
       
        const tokenresponse = await axios.post(process.env.REACT_APP_CENTRAL_BACK + '/invitation/verifytoken', { token: token });
     
        return tokenresponse.data;
      } catch (error) {

        if (error.response) {
          // The request was made and the server responded with a status code that is not in the range of 2xx
          alert(`Failed to verify token: ${getDecodedBody(error.response.data)}`);
        } else if (error.request) {
          // The request was made but no response was received
          alert("Failed to verify token: No response from server");
        } else {
          // Something happened in setting up the request that triggered an Error
          alert(`Failed to verify token: ${error.message}`);
        }

        alert("Your token is either invalid or expired or your login has already been created. Token expires after 5 days");
        throw error;
      }
    };

    const loginPublicUser = async (myuserData) => {
      try {
        const publicLoginPayload = {
          clientNr: myuserData.clientNr,
          explorerId: myuserData.explorerId,
          email: myuserData.email,
          chatbotKey: myuserData.chatbotKey,
        };

       

        const publicUser = await axios.post(process.env.REACT_APP_CENTRAL_BACK + '/invitation/publiclogin', publicLoginPayload);
        setClient(myuserData.clientNr);
        setPublicUser(publicUser.data);
        return publicUser.data;
      } catch (err) {
        if (err.response) {
          // The request was made and the server responded with a status code that is not in the range of 2xx
          alert(`Failed to login public user: ${getDecodedBody(err.response.data)}`);
        } else if (err.request) {
          // The request was made but no response was received
          alert("Failed to create user: No response from server");
        } else {
          // Something happened in setting up the request that triggered an Error
          alert(`Failed to create user: ${err.message}`);
        }
      }
    };

    const initialize = async () => {
      try {
        const myuserData = await verifyToken();
        const myPublicUser = await loginPublicUser(myuserData);
       
        if (!myuserData.emailWillbeRequested)
        {
         
          await handleSubmit(myuserData.clientNr, myPublicUser);
        }
        else{
          console.log("NO AUTOMATIC LOAD", myuserData.emailWillbeRequested);
        }
      } catch (error) {
        // Handle any error that occurred in either function
      }
    };
    initialize();
  }, [token]);

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  const handleSubmit = async (client, publicUser, e) => {
    if (e) e.preventDefault(); // Prevent default only if `e` is defined

      // set storage elements and redirect
     
      const userSettingsString = JSON.stringify(publicUser);
      localStorage.setItem('user', userSettingsString);

      if (email)
        {
          const emailPayload = {
            clientNr: client,
            email: email,
          };
          try{
          await axios.post(process.env.REACT_APP_CENTRAL_BACK + '/invitation/registerpublicemail', encodebody(emailPayload));
          }
          catch(error)
          {
            console.log("error saving email")
          }
        }

      // do a complete load
      window.location.href = '/';
  };

  return (
    <Modal open={true} showCloseButton={false} maxWidth="40rem">
      <Modal.Title>Welcome to the {client} GWOCU Studio workspace</Modal.Title>
      <Modal.Body>
        <Form onSubmit={(e) => handleSubmit(client, publicUser, e)}>
          <Form.Row>
            <p>For an enhanced experience, please consider setting your email address. This step is optional but recommended.</p>
          </Form.Row>
          <Form.Control>
            <Form.Label htmlFor="email">Email</Form.Label>
            <InputText
              type="email"
              id="email"
              value={email}
              onChange={handleEmailChange}
              />
            </Form.Control>
          <Form.Row>
            <Button type="submit">Continue</Button>
          </Form.Row>
        </Form>
      </Modal.Body>
    </Modal>
  );
}

export default Modalacceptpublicinvite;
