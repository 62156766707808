import React, { useState, useEffect } from "react";
import axios from "axios";
import { Form, Icon, InputText, TextEditor, Toolbar } from "../../UI";
import {getDecodedBody, encodebody} from "../../utils/utils.js";
import HelpCenterIcon from "@mui/icons-material/HelpCenter";

function Workflowcomplianceview({ clientNr, explorerId, productName, name, authorization, updateTreeView  }) {
  const [workflow, setWorkflow] = useState(null);

  useEffect(() => {
    const fetchWorkflow = async () => {
      const apiUrl = process.env.REACT_APP_CENTRAL_BACK + '/workflow/query';

      const requestBody = {
        clientNr,
        explorerId,
        productName,
        name,
      };

      try {
        const response = await axios.post(apiUrl, encodebody(requestBody), {
          headers: {
            'Content-Type': 'application/json',
          },
        });
        const data = getDecodedBody(response.data);
        setWorkflow(data);
      } catch (error) {
        console.error('Error fetching workflow:', error);
      }
    };

    fetchWorkflow();
  }, [clientNr, explorerId, productName, name]);

  const handleComplianceDescriptionChange = (value) => {
    setWorkflow((prevWorkflow) => ({
      ...prevWorkflow,
      complianceDescription: value,
    }));
  };

  const handleUpdate = async () => {
    const apiUrl = process.env.REACT_APP_CENTRAL_BACK + '/workflow/update';

    // Define the request body
    const requestBody = {
      clientNr: clientNr,
      explorerId: explorerId,
      productName:productName,
      name:name,
      complianceDescription: workflow.complianceDescription,
    };

      const myResponse = await axios.post(apiUrl, encodebody(requestBody));
      alert("Workflow was succesfully updated.");
  };

  return (workflow
    ? (
        <>
          <header className="section-right__toolbar-container">
            <Toolbar>
              {(authorization.designer || authorization.owner) &&
                <Toolbar.Button onClick={handleUpdate}>Update</Toolbar.Button>
              }
              <Toolbar.Item>
                <Icon href="https://wiki.gwocu.com/en/GWOCU-Studio/workflows-detail-panel#workflowcompliance-section">
                  <HelpCenterIcon />
                </Icon>
              </Toolbar.Item>
            </Toolbar>
          </header>
          <div className="section-right__content">
            <Form className="section-right__form">
            <Form.Control>
              <Form.Label htmlFor="workflowName">Workflow Name</Form.Label>
              <InputText
                id="workflowName"
                value={workflow.name}
                disabled
              />
            </Form.Control>
            <Form.Control>
              <Form.Label htmlFor="workflowComplianceDescription">Compliance Description</Form.Label>
              <TextEditor
                id="workflowComplianceDescription"
                value={workflow.complianceDescription}
                onChange={handleComplianceDescriptionChange}
                readOnly={!authorization.designer && !authorization.owner}
              />
            </Form.Control>
            </Form>
          </div>
        </>
    ) : (
      <p>Loading Workflow information...</p>
    )
  );
}

export default Workflowcomplianceview;
