import { useEffect, useState, useContext } from "react";
import { useHistory, useLocation } from "react-router";
import axios from "axios";
import { AuthContext } from "../../context/AuthContext";
import { encodebody, getDecodedBody } from "../../utils/utils.js";
import { Button, Checkbox, Form, InputText, Modal } from "../../UI";
import "./updateuseradmin.css";

export default function Updateuseradmin() {
  const location = useLocation();
  const { targetuser, explorerId } = location.state;
  const [explorer, setExplorer] = useState(null);
  const [loadingExplorer, setLoadingExplorer] = useState(true);
  const history = useHistory();
  const { user } = useContext(AuthContext);
  const goBack = () => history.goBack()

  useEffect(() => {
      const fetchExplorerData = async () => {
          const myPayload = {
              clientNr: user.clientNr,
              chatbotKey: user.chatbotKey,
              email: targetuser.email // Use targetuser's email here
          };
          try {
              const response = await axios.post(process.env.REACT_APP_CENTRAL_BACK + "/users/query", encodebody(myPayload));
              const responseData = getDecodedBody(response.data);

              const explorerData = responseData.explorers.find(exp => exp.name === explorerId);
              setExplorer(explorerData);
          } catch (error) {
              console.error("Error fetching explorer data:", error);
          } finally {
              setLoadingExplorer(false);
          }
      };

      fetchExplorerData();
  }, [user, targetuser, explorerId]);

  const handleInputChange = (e) => {
      const { name, value } = e.target;
      setExplorer((prevExplorer) => ({
          ...prevExplorer,
          [name]: value
      }));
  };

  const handleCheckboxChange = (e) => {
      const { name, checked } = e.target;
      setExplorer((prevExplorer) => ({
          ...prevExplorer,
          [name]: checked
      }));
  };

  const handleClick = async (e) => {
      e.preventDefault();
      const updatedExplorers = targetuser.explorers.map(exp =>
          exp.name === explorer.name ? explorer : exp
      );

      const updatedUser = {
          clientNr: user.clientNr,
          chatbotKey: targetuser.chatbotKey,
          email: targetuser.email,
          explorers: updatedExplorers
      };

      const body = encodebody(updatedUser);
      try {
          await axios.post(process.env.REACT_APP_CENTRAL_BACK + `/users/update/`, body);
          alert("User has been updated");
          goBack();
      } catch (err) {
          alert("Error during save operation: " + (err.response ? JSON.stringify(getDecodedBody(err.response.data)) : err.message));
      }
  };

  return (
    <Modal open={true} onClose={goBack} maxWidth="40rem">
      <Modal.Title>Edit user</Modal.Title>
      <Modal.Body>
        <Form onSubmit={handleClick}>
          <InputText defaultValue={targetuser.email} disabled fullWidth />
          <Form.Control>
            <Form.Label>Explorer Name:</Form.Label>
            <InputText
              type="text"
              name="name"
              value={explorer?.name || ""}
              onChange={handleInputChange}
              disabled
              fullWidth
            />
          </Form.Control>
          <Form.Col>
            <Checkbox
              type="checkbox"
              name="designer"
              checked={explorer?.designer || false}
              onChange={handleCheckboxChange}
            >
              Designer
            </Checkbox>
            <Checkbox
              name="owner"
              checked={explorer?.owner || false}
              onChange={handleCheckboxChange}
            >
              Owner
            </Checkbox>
            <Checkbox
              type="checkbox"
              name="reader"
              checked={explorer?.reader || false}
              onChange={handleCheckboxChange}
            >
              Reader
            </Checkbox>
          </Form.Col>
          <Form.Col>
            <Button type="submit" disabled={loadingExplorer}>Update</Button>
          </Form.Col>
        </Form>
      </Modal.Body>
    </Modal>
  );
}
