import React, { useContext, useState } from "react";
import axios from "axios";
import { AuthContext } from "../../context/AuthContext";
import { Button, Modal, ProgressBar } from "../../UI";
import { encodebody, getDecodedBody } from "../../utils/utils.js";
import "./fileupload.css";

const PF = process.env.REACT_APP_PUBLIC_FOLDER;

function FileUpload({clientNr, explorerId}) {
  const { user } = useContext(AuthContext);
  const fileInputRef = React.createRef();
  const file2InputRef = React.createRef();
  const [progress, setProgress] = useState(0);
  const [showProgressBar, setShowProgressBar] = useState(false);
  const [confirmationOpen, setConfirmationOpen] = useState(false); // State for confirmation dialog
  const [confirmation2Open, setConfirmation2Open] = useState(false);
  const [filename, setFilename] = useState("");
  const [files, setFiles] = useState(null); // State for uploaded files

  const handleCancelUpload = () => {
    // Close the confirmation dialog
    setConfirmationOpen(false);
    setConfirmation2Open(false);

    // Clear the file input when the user cancels
    fileInputRef.current.value = "";
    file2InputRef.current.value = "";

    // Reset the files state
    setFiles(null);
  };

  const handleFileChange = () => {
    const selectedFiles = fileInputRef.current.files;
  

    if (selectedFiles.length > 0) {
     
      const selectedFilename = selectedFiles[0].name;
      setFilename(selectedFilename);
      // Display confirmation dialog before uploading
      setConfirmationOpen(true);
      setConfirmation2Open(false);
      // Set the selected files in the state
      setFiles(selectedFiles[0]);
    } else {
      return;
    }
  };

  const handleFile2Change = () => {
    const selectedFiles = file2InputRef.current.files;
   
    if (selectedFiles.length > 0) {
     
      const selectedFilename = selectedFiles[0].name;
      setFilename(selectedFilename);
      // Display confirmation dialog before uploading
      setConfirmation2Open(true);
      setConfirmationOpen(false);
      // Set the selected files in the state
      setFiles(selectedFiles[0]);
    } else {
      return;
    }
  };

  const confirmUpload = () => {
    // Close the confirmation dialog
    setConfirmationOpen(false);

    // Set progress to 25% before uploading
    setProgress(25);
    setShowProgressBar(true); // Show the progress bar

    const formData = new FormData();
    formData.append("clientNr", clientNr);
    formData.append("explorerId", explorerId);
    formData.append("file", files);

    axios
      .post(process.env.REACT_APP_CENTRAL_BACK + "/uploadapidef", formData)
      .then((response) => {
        const myfilename = files.name;
        
        // Set progress to 25% before calling API
        setProgress(50);
        callAPI(clientNr, explorerId, process.env.REACT_APP_HOST_CENTRAL_BACK + "/upload/postmandef/"+clientNr+ "/" + explorerId + "/" + myfilename);
      })
      .catch((error) => {
        alert("Error during upload operation: " + (error.response ? JSON.stringify(getDecodedBody(error.response.data)) : error.message));
      });
  };

  function callAPI(clientNr,explorerId,url) {
    const originalbody = {
      clientNr: clientNr,
      explorerId: explorerId,
      url: url
    };
    const body = originalbody;
    axios
      .post(process.env.REACT_APP_CENTRAL_BACK + "/apiimport/postman", encodebody(body))
      .then((response) => {
        setProgress(90);
        alert("finished uploading")
        setShowProgressBar(false);
        window.location.reload();
      })
      .catch((error) => {
        alert("Error during upload operation: " + (error.response ? JSON.stringify(getDecodedBody(error.response.data)) : error.message));
      });
  }

  const confirmUpload2 = () => {
    // Close the confirmation dialog
    setConfirmation2Open(false);

    // Set progress to 25% before uploading
    setProgress(25);
    setShowProgressBar(true); // Show the progress bar

    const formData = new FormData();

    // const clientNr = clientNr;
    // const explorerId = explorerId

    formData.append("clientNr", clientNr);
    formData.append("explorerId", explorerId);
    formData.append("file", files);

    axios
      .post(process.env.REACT_APP_CENTRAL_BACK + "/uploadapidef2", formData)
      .then((response) => {
        const myfilename = files.name;
        // Set progress to 25% before calling API
        setProgress(50);
        callAPI2(clientNr, explorerId, process.env.REACT_APP_HOST_CENTRAL_BACK + "/upload/swaggerdef/"+clientNr+ "/" + explorerId + "/" + myfilename);
      })
      .catch((error) => {
        alert("Error during upload operation: " + (error.response ? JSON.stringify(getDecodedBody(error.response.data)) : error.message));
      });
  };

  function callAPI2(clientNr,explorerId, url) {
    const originalbody = {
      clientNr: clientNr,
      explorerId: explorerId,
      url: url
    };
    const body = originalbody;
    axios
      .post(process.env.REACT_APP_CENTRAL_BACK + "/apiimport/openapi", encodebody(body))
      .then((response) => {
        setProgress(90);
        alert("finished uploading")
        setShowProgressBar(false);
        window.location.reload();
      })
      .catch((error) => {
        alert("Error during upload operation: " + (error.response ? JSON.stringify(getDecodedBody(error.response.data)) : error.message));
      });
  }

  return (
    <div>
      <div className="uploadBox">
        <div className="uploadelements">
          <label htmlFor="file" className="my-custom-file-label">
            Upload Postman APIS v2.1
          </label>
          <input
            type="file"
            id="file"
            name="file"
            className="fileInput"
            acceptCharset="utf-8"
            ref={fileInputRef}
            onChange={handleFileChange} // Trigger file upload on change
          />
          <label htmlFor="file2" className="my-custom-file-label">
            Upload Swagger OpenApi v3.0
          </label>
          <input
            type="file"
            id="file2"
            name="file2"
            className="fileInput"
            acceptCharset="utf-8"
            ref={file2InputRef}
            style={{ display: "none" }}
            onChange={handleFile2Change} // Trigger file upload on change
          />
        </div>
      </div>
      <div className="file-upload__progress-bar">
        {showProgressBar && <ProgressBar progress={Math.round(progress)} />}
      </div>
        <Modal open={confirmationOpen} onClose={handleCancelUpload} maxWidth="40rem">
          <Modal.Title>Confirm Upload</Modal.Title>
          <Modal.Body className="confirm-upload__body">
            <p>
              <b>Are you sure, you want to upload the Postman collection?</b>
            </p>
            <p>If there are API definitions with the same name, it will overwrite them.</p>
            <p><b>{filename}</b></p>
          </Modal.Body>
          <Modal.Footer>
            <Button color="secondary" onClick={handleCancelUpload}>Cancel</Button>
            <Button onClick={confirmUpload}>Upload</Button>
          </Modal.Footer>
        </Modal>
        <Modal open={confirmation2Open} onClose={handleCancelUpload} maxWidth="40rem">
          <Modal.Title>Confirm Upload</Modal.Title>
          <Modal.Body className="confirm-upload__body">
            <p>
              <b>Are you sure, you want to upload the OpenAPI 3.0 definitions?</b>
            </p>
            <p>If there are API definitions with the same name, it will overwrite them.</p>
            <p><b>{filename}</b></p>
          </Modal.Body>
          <Modal.Footer>
            <Button color="secondary" onClick={handleCancelUpload}>Cancel</Button>
            <Button onClick={confirmUpload2}>Upload</Button>
          </Modal.Footer>
        </Modal>
    </div>
  );
}

export default FileUpload;
