import React, { useState, useEffect, useContext } from "react";
import axios from "axios";
import { AuthContext } from "../../context/AuthContext";
import { Button, Checkbox, Form, InputText, Modal, Select } from "../../UI";
import { encodebody, getDecodedBody } from "../../utils/utils.js";
import FileCopyIcon from '@mui/icons-material/FileCopy';

function Modalpublicinvite({ clientNr, explorerId, open, onClose }) {
  const { user } = useContext(AuthContext);
  const [isPublicInvite, setIsPublicInvite] = useState(false);
  const [isEmailWillbeRequested, setIsEmailWillbeRequested] = useState(false);
  const [url, setUrl] = useState('');
  const [selectedAuth, setSelectedAuth] = useState("reader");

  useEffect(() => {
    const fetchPublicInviteStatus = async () => {
      try {
        const payload = {
          clientNr: clientNr,
          explorerId: explorerId,
        };

        const response = await axios.post(`${process.env.REACT_APP_CENTRAL_BACK}/invitation/GetPublicInviteStatus`, encodebody(payload));
        const responseData = getDecodedBody(response.data);
        const status = responseData.publicInvite;
        setIsPublicInvite(!!status); // Set to false if status is falsy
      } catch (error) {
        setIsPublicInvite(false);
        console.error('Failed to fetch public invite status:', error);
      }
    };

    fetchPublicInviteStatus();
  }, [clientNr, explorerId]);

  const handlePublicInviteChange = async (event) => {
    const value = event.target.checked;
    setIsPublicInvite(value);

    try {
      const payload = {
        clientNr: clientNr,
        explorerId: explorerId,
        publicInvite: value
      };

      await axios.post(`${process.env.REACT_APP_CENTRAL_BACK}/invitation/SetPublicInviteStatus`, encodebody(payload));
     
    } catch (error) {
      console.error('Failed to update public invite status:', error);
    }
  };

  const handleEmailWillbeRequestedChange = async (event) => { 
    const value = event.target.checked;
    setIsEmailWillbeRequested(value);
  }

  const handleAuthChange = (event) => {
    setSelectedAuth(event.target.value);
  };

  const handleGenerateUrl = async () => {
    try {
      // get client
      const clientResponse = await axios.post(`${process.env.REACT_APP_CENTRAL_BACK}/clients/query`, encodebody({clientNr: clientNr}));

      const payload = {
        clientNr: clientNr,
        explorerId: explorerId,
        chatbotKey: user.chatbotKey,
        email:"public@gwocu.com",
        authorization: selectedAuth,
        emailWillbeRequested: isEmailWillbeRequested,
        client: clientResponse.data,
      };
      const response = await axios.post(`${process.env.REACT_APP_CENTRAL_BACK}/invitation/generate-token`, encodebody(payload));
      const token = getDecodedBody(response.data);
      const generatedUrl = `${process.env.REACT_APP_FROND_END}/acceptpublicinvite?token=${token}`;
      setUrl(generatedUrl);
      // registe public user. upinsert it
      // create explorer
      const myExplorer = {
        name: explorerId,
        designer: selectedAuth === "designer",
        owner: selectedAuth === "owner",
        reader: selectedAuth === "reader"
      };
      const explorers = [];
      explorers.push(myExplorer);

      const userPayload =
      {
        clientNr: clientNr,
        explorerId: explorerId,
        email: "public@gwocu.com",
        username:  "public@gwocu.com",
        chatbotKey: user.chatbotKey,
        groups: ["chatbotDesigners"],
        explorers: explorers,
        isAdmin: false

      }
     

      const myResult = await axios.post(process.env.REACT_APP_CENTRAL_BACK + '/invitation/registerpublicuser', encodebody(userPayload));

    } catch (error) {
      console.error('Failed to generate URL:', error);
    }
  };


  const handleCopyUrl = () => {
    navigator.clipboard.writeText(url);
    alert('URL copied to clipboard!');
  };

  return (
    <Modal open={open} onClose={onClose} maxWidth="40rem">
      <Modal.Title>Public Invite Settings</Modal.Title>
      <Modal.Body>
        <Form onSubmit={handleGenerateUrl}>
          <Form.Col>
            <Checkbox id="invite-label"
              checked={isPublicInvite}
              onChange={handlePublicInviteChange}
              required
              >
              Enable Public Invite
            </Checkbox>
            <Checkbox
              checked={isEmailWillbeRequested}
              onChange={handleEmailWillbeRequestedChange}
            >
              Email will be requested
            </Checkbox>
          </Form.Col>
          <Form.Control>
            <Select
              value={selectedAuth}
              onChange={handleAuthChange}
              disabled={!isPublicInvite}
              options={["Designer", "Owner", "Reader"].map(role => ({label: role, value: role.toLowerCase()}))}
            />
          </Form.Control>
          {url && (
            <Form.Row>
              <InputText value={url} readOnly />
              <Button onClick={handleCopyUrl} title="Copy URL">
                <FileCopyIcon />
              </Button>
            </Form.Row>
          )}
            <Form.Row>
              <Button color="secondary" onClick={onClose}>Close</Button>
              <Button type="submit" disabled={!isPublicInvite}>Generate URL</Button>
            </Form.Row>
        </Form>
      </Modal.Body>
    </Modal>
  );
}

export default Modalpublicinvite;
