import styled from "styled-components";
import { blink } from "./animations";

const StyledCheckbox = styled.label`
  display: flex;
  gap: 0.5em;
  cursor: pointer;

  & input[type="checkbox"] {
    -webkit-appearance: none;
    appearance: none;
    background-color: var(--checkbox-bg-clr);
    color: var(--input-txt-clr);
    height: 1.5em;
    width: 1.5em;
    border: 2px solid var(--checkbox-border-clr);
    border-radius: 0.5em;
    cursor: inherit;
    display: grid;
    place-content: center;
    transition:
      background-color var(--transition-duration-normal, 250ms) ease-in-out,
      border-color var(--transition-duration-normal, 250ms) ease-in-out;
  }

  & input[type="checkbox"]::before {
    content: "";
    width: 0.95em;
    height: 0.95em;
    transform: scale(0);
    transition: transform var(--transition-duration-normal, 250ms) ease-in-out;
    box-shadow: inset 1em 1em var(--checkbox-checkmark-clr);
    border-radius: 0.25em;
    transform-origin: bottom left;
    clip-path: polygon(14% 44%, 0 65%, 50% 100%, 100% 16%, 80% 0%, 43% 62%);
  }

  & input[type="checkbox"]:checked::before {
    transform: scale(1);
  }

  & input[type="checkbox"]:checked {
    background-color: var(--checkbox-bg-clr-selected);
    border-color: var(--checkbox-border-clr-hover);
  }

  & input[type="checkbox"]:hover,
  & input[type="checkbox"]:focus-visible {
    border-color: var(--checkbox-border-clr-hover);
  }

  & input:focus-visible {
    outline: 2px solid var(--checkbox-border-clr-hover);
    outline-offset: 0;
    animation: ${blink} 2s infinite;
  }

  & input[type="checkbox"]:disabled {
    border: 2px solid var(--checkbox-border-clr-disabled);
  }

  & input[type="checkbox"]:disabled:checked {
    background-color: var(--checkbox-bg-clr-selected-disabled);
  }

  & input[type="checkbox"]:disabled::before {
    box-shadow: inset 1em 1em var(--checkbox-checkmark-clr-disabled);
  }

  & span {
    flex: 1;
    display: flex;
    align-items: center;
    color: currentColor;
    gap: 0.8rem;
  }

  &.disabled {
    cursor: not-allowed;
    color: var(--input-txt-clr-disabled);
  }

  &.invalid {
    color: var(--input-txt-clr-invalid);
  }

  &.invalid input[type="checkbox"],
  &.invalid input[type="checkbox"]:hover,
  &.invalid input[type="checkbox"]:focus-visible {
    background-color: var(--checkbox-bg-clr-invalid);
    border-color: var(--checkbox-border-clr-invalid);
    outline-color: var(--checkbox-border-clr-invalid);
  }

  &.invalid input[type="checkbox"]:checked {
    background-color: var(--checkbox-bg-clr-selected-invalid);
  }

  &.invalid input[type="checkbox"]:checked::before {
    box-shadow: inset 1em 1em var(--checkbox-checkmark-clr-invalid);
  }
`;

/**
 * Gwocu's checkbox component
 *
 * A customizable checkbox input with optional error styling and other features.
 *
 * @component
 * @param {Object} props - The props for the checkbox component.
 * @param {boolean} [props.checked=false] - If `true`, the checkbox is checked. Default value: `false`.
 * @param {React.ReactNode} [props.children=undefined] - The content shown next to the checkbox. Default value: `undefined`.
 * @param {string} [props.className=undefined] - Adds a custom className for the checkbox. Default value: `undefined`.
 * @param {boolean} [props.disabled=false] - If `true`, the checkbox is disabled. Default value: `false`.
 * @param {boolean} [props.error=false] - If `true`, the checkbox styling indicates an error. Default value: `false`.
 * @param {string} [props.id=undefined] - The id of the checkbox. Default value: `undefined`.
 * @param {string} [props.name=undefined] - The name of the checkbox. Default value: `undefined`.
 * @param {function} [props.onChange=undefined] - The `change` event handler. Default value: `undefined`.
 * @param {boolean} [props.required=false] - If `true`, checking the checkbox is required. Default value: `false`.
 * @returns {JSX.Element} The rendered checkbox component.
 *
 * @example
 * <Checkbox name="terms" checked={terms} onChange={handleChange}>I accept the terms and conditions</Checkbox>
 */
export const Checkbox = ({
  checked = false,
  children,
  disabled = false,
  error = false,
  id,
  name,
  onChange,
  required = false,
}) => {

  const className = `${disabled ? "disabled" : ""} ${error ? "invalid" : ""}`;

  return (
      <StyledCheckbox className={className}>
        <input
          id={id}
          type="checkbox"
          name={name}
          checked={checked}
          disabled={disabled}
          onChange={onChange}
          required={required}
        />
        {children && <span>{children}</span>}
    </StyledCheckbox>
  );
};