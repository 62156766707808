import React, { useState, useEffect } from 'react';
import axios from 'axios';
import ReactJson from 'react-json-view';
import { Terminal } from '../../UI';
import { makeCurlComponentFromApi, makeCurlComponentFromApiExecutionResult } from "../../utils/api-spec-util.js";
import { getDecodedBody, encodebody } from "../../utils/utils.js";

const Workflowterminal = ({ clientNr, explorerId, productName, name, authorization }) => {
  const [explorer, setExplorer] = useState([]);
  const [workflowStarted, setWorkflowStarted] = useState(false);
  const [apiList, setApiList] = useState([]);
  let current_api_index = 0;

  const commands = {
    whoami: "jackharper",
    cd: (directory) => {
      const myreturn = `changed path to ${directory}`;
      return myreturn;
    },
    run: async () => {
      setWorkflowStarted(true);
      current_api_index = 0;

      try {
      if (apiList.length === 0)
        { return <div className="terminal__message"> <br></br>{'There are no APIS to execute in this workflow'}</div>; }
      current_api_index = 0;
      const myOutput = await constructOutputAPIExecution(current_api_index)

      return (
      <div className="terminal__message">
        <br></br>
        <div>Workflow running..</div>
        <br></br>
        <div>Executed API..{apiList[current_api_index].api.name}</div>
        <br></br>
        {myOutput.curl}
        <br></br>
        <ReactJson src={myOutput.executionResult} theme="apathy" name={null} collapsed={1} />
        <div>Type "next" to execute next API or "stop" to end the workflow</div>
      </div>
      );

      } catch (error) {
        console.error('Error during API execution:', error);
        return <div className="terminal__message terminal__message--error">An error occured during API execution</div>;
      }
    },
    next: async () => {
      if (!workflowStarted)
      {  return <div className="terminal__message"> Please start workflow first by typing "run"</div>;}
      current_api_index = current_api_index + 1;
      if (current_api_index === apiList.length) // we have overpassed the length of the api list
      {
        current_api_index = 0;
        setWorkflowStarted(false);
        return <div className="terminal__message"> We have reached the end of the workflow. You can start the workflow again by typing "run"</div>;
      }
      try {
      const myOutput = await constructOutputAPIExecution(current_api_index)
      return (
      <div className="terminal__message">
      <br></br>
      <div>Executed API..{apiList[current_api_index].api.name}</div>
      <br></br>{myOutput.curl}<br></br>
      <ReactJson src={myOutput.executionResult} theme="apathy"   name={null} collapsed={1} />
      <div>Type "next" to execute next API or "stop" to end the workflow</div>
      </div>
      )
      } catch (error) {
        console.error('Error during API execution:', error);
        return <div className="terminal__message terminal__message--error">An error occured during API execution. Type "run" to start the workflow again!</div>;
      }
    },
    stop: async () => {
      if (!workflowStarted)
      {  return <div className="terminal__message"> Please start workflow first by typing "run"</div>;}
      current_api_index = 0;
      setWorkflowStarted(false)
      try {
        return <div className="terminal__message"> <br></br>Workflow stopped! You can start the workflow again by typing "run"</div>;
      } catch (error) {
        console.error('Error during API execution:', error);
        return <div className="terminal__message terminal__message--error">An error occured during API execution. Type "run" to start the workflow again!</div>;
      }
    },
  };

  useEffect(() => {
    fetchApi();
  }, []);

  const constructOutputAPIExecution = async (index) => {
    if (index < apiList.length)
    {
      const curlComponent = await makeCurlComponentFromApi(explorer,name,apiList[index].taskId, apiList[index].api)
     
      const executionResultComponent = await makeCurlComponentFromApiExecutionResult(explorer, name, apiList[index].taskId, apiList[index].api)
      const myReturnObject = { curl: curlComponent, executionResult: executionResultComponent  }
      return myReturnObject
    } else {
      setWorkflowStarted(false);
      return <div>Workflow Completed</div>
    }
  };

  const fetchApi = async () => {
    try {
      const myApibody = {
        clientNr: clientNr,
        explorerId: explorerId,
        workflowName: name
      };

      const response = await axios.post(process.env.REACT_APP_CENTRAL_BACK + "/link/queryorderedapi", encodebody(myApibody));
      const myApiList = getDecodedBody(response.data);

      setApiList(myApiList.filter(obj => Object.keys(obj).length > 0));


      const myExplorerbody = {
        clientNr: clientNr,
        explorerId: explorerId
      };
      const Eresponse = await axios.post(process.env.REACT_APP_CENTRAL_BACK + "/explorer/query", encodebody(myExplorerbody));
      const myExplorer = getDecodedBody(Eresponse.data);
      setExplorer(myExplorer);
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <>
      <div className="section-right__toolbar-container section-right__toolbar-container--empty" />
      <div className="section-right__content">
        <div className="section-right__terminal-container">
          <Terminal
            commands={commands}
            welcomeMessage={
              <div>
                Welcome to the workflow execution terminal.
                <br />
                Please type in "run" to start the workflow.
                <br />
              </div>
            }
          />
        </div>
      </div>
    </>
  );
};

export default Workflowterminal;

