import { keyframes } from "styled-components";

export const blink = keyframes`
  50% {
    border-color: transparent;
  }
`;

export const fadeIn = keyframes`
  0% {
    background: rgba(0,0,0,0);
  }
  100% {
    background: var(--clr-overlay);
  }
`;

export const showModal = keyframes`
  from {
    opacity: 0;
    /* transform: scale(0.7); */
    transform: translate(0, 35px);
  }
  to {
    opacity: 1;
    /* transform: scale(1); */
    transform: translate(0);
  }
`;

export const rotate = keyframes`
  100% {
    transform: rotate(360deg);
  }
`;

export const dash = keyframes`
  0% {
    stroke-dasharray: 1, 150;
    stroke-dashoffset: 0;
  }
  50% {
    stroke-dasharray: 90, 150;
    stroke-dashoffset: -35;
  }
  100% {
    stroke-dasharray: 90, 150;
    stroke-dashoffset: -124;
  }
`;
