import React, { useState, useEffect } from "react";
import axios from "axios";
import { Button, Form, Modal, Textarea } from "../../UI";
import {encodebody, getDecodedBody} from "../../utils/utils.js";

function Modalconfiguration({ clientNr, explorerId, open, onClose }) {
  const [selectedConfigurationYaml, setSelectedConfigurationYaml] = useState("No Description Yet");

  useEffect(() => {
    if (!open) return;
    const fetchConfiguration = async () => {
      const apiUrl = `${process.env.REACT_APP_CENTRAL_BACK}/explorer/query`;

      // Define the request body
      const requestBody = {
        clientNr: clientNr,
        explorerId: explorerId,
      };

      try {
        // Make a POST request to fetch the configuration
        const response = await axios.post(apiUrl, encodebody(requestBody), {
          headers: {
            'Content-Type': 'application/json',
          },
        });
        // Set the fetched configuration data to the state
        const responseData = getDecodedBody(response.data);
        setSelectedConfigurationYaml(responseData.yaml);
      } catch (error) {
        console.error('Error fetching product:', error);
      }
    };

    fetchConfiguration();
  }, [open]); // Empty dependency array ensures the effect runs only once after the initial render

  const handleYamlChange = (event) => {
    setSelectedConfigurationYaml(event.target.value)
  }

  const handleSave = async (e) => {
    e.preventDefault();

    // Perform save logic with selectedSource and selectedTarget
    // You can use these values to update your backend or state, as needed
    if (await handleSaveYaml(selectedConfigurationYaml))
    {
      onClose();
    }
  };

  async function handleSaveYaml(configuration) {
    try {
      const mypayload = {
        clientNr: clientNr,
        explorerId: explorerId,
        yaml: configuration
      };

    

      const response = await axios.post(process.env.REACT_APP_CENTRAL_BACK + "/explorer/update", encodebody(mypayload));
      const responseData = getDecodedBody(response.data);
      // Check if the response indicates an error
      if (responseData && responseData.error) {
        // Display an alert with the error data
        alert(`Error: ${responseData.error}`);
        return false;
      }

      // setNodesAdded(nodesAdded + 1);
      return true;
    } catch (error) {
      // Handle unexpected errors (e.g., network issues)
      console.error("An unexpected error occurred:", error);
      alert("An unexpected error occurred. Please try again.");
      return false;
    }
  };

  return (
    <Modal open={open} onClose={onClose} maxWidth="80rem">
      <Modal.Title>Configuration</Modal.Title>
      <Modal.Body>
        <Form onSubmit={handleSave}>
          <Form.Control>
            <Form.Label htmlFor="yaml">Yaml</Form.Label>
            <Textarea
              id="yaml"
              value={selectedConfigurationYaml}
              onChange={handleYamlChange}
              rows="20"
              required
              />
          </Form.Control>
          <Form.Row>
            <Button color="secondary" onClick={onClose}>Close</Button>
            <Button type="submit">Save</Button>
          </Form.Row>
        </Form>
      </Modal.Body>
    </Modal>
  );
}


export default Modalconfiguration;
