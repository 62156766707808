import React, { useState, useEffect } from 'react';
import axios from "axios";
import { Form, Icon, InputText, TextEditor, Toolbar } from '../../UI';
import {encodebody, getDecodedBody} from "../../utils/utils.js";
import HelpCenterIcon from '@mui/icons-material/HelpCenter';

function Taskcomplianceview({ clientNr, explorerId, workflowName, taskId, authorization,updateGraphView }) {
  const [task, setTask] = useState(null);

  useEffect(() => {
    const fetchTask = async () => {
      const apiUrl = `${process.env.REACT_APP_CENTRAL_BACK}/task/query`;

      // Define the request body
      const requestBody = {
        clientNr,
        explorerId,
        workflowName,
        taskId,
      };

     

      try {
        // Make a POST request to fetch the task
        const response = await axios.post(apiUrl, encodebody(requestBody), {
          headers: {
            'Content-Type': 'application/json',
          },
        });

        const data = getDecodedBody(response.data);
        // Set the fetched product data to the state
        setTask(data);
        // Set the markdown
      } catch (error) {
        console.error('Error fetching task:', error);
      }
    };

    fetchTask();
  }, [workflowName, taskId]); // Dependency array ensures the effect runs when these variables change


 const handleComplianceDescriptionChange = (value) => {
  setTask((prevTask) => ({
    ...prevTask,
    complianceDescription: value,
  }));
};

  const handleUpdate = async () => {
    const apiUrl = process.env.REACT_APP_CENTRAL_BACK + '/task/update';

    // Define the request body
    const requestBody = {
      clientNr: clientNr,
      explorerId: explorerId,
      workflowName:workflowName,
      taskId:taskId,
      complianceDescription: task.complianceDescription
    };

      const myResponse = await axios.post(apiUrl, encodebody(requestBody));
      alert("Task was succesfully updated.");
  };

  return (task
    ? (
        <>
          <header className="section-right__toolbar-container">
            <Toolbar>
              {(authorization.designer || authorization.owner) && (
                <Toolbar.Button onClick={handleUpdate}>Update</Toolbar.Button>
              )}
              <Toolbar.Item>
                <Icon href="https://wiki.gwocu.com/en/GWOCU-Studio/tasks-detail-panel#taskcompliance-section">
                  <HelpCenterIcon />
                </Icon>
              </Toolbar.Item>
            </Toolbar>
          </header>
          <div className="section-right__content">
            <Form className="section-right__form">
              <Form.Control>
                <Form.Label htmlFor="taskName">Task Name</Form.Label>
                <InputText
                  id="taskName"
                  value={task.name}
                  disabled
                />
              </Form.Control>
              <Form.Control className="panel__text-editor-section">
                <Form.Label htmlFor="taskComplianceDescription">Compliance Description</Form.Label>
                <TextEditor
                  id="taskComplianceDescription"
                  value={task.complianceDescription}
                  onChange={handleComplianceDescriptionChange}
                  readOnly={!authorization.designer && !authorization.owner}
                  />
              </Form.Control>
            </Form>
          </div>
        </>
      ) : (
        <p>Loading Task information...</p>
      )
  );
}

export default Taskcomplianceview;
