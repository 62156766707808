import React, { useState, useEffect } from "react"
import axios from "axios";
import { toast } from 'react-toastify'; // import toastify components
import { useTheme } from "../../context/ThemeContext.js";
import { SyntaxHighlighter } from "../../UI";
import { generateJavaScriptCode,generatePythonCode, getDecodedBody, encodebody } from "../../utils/utils.js";
import 'react-toastify/dist/ReactToastify.css'; // import toastify CSS

const WorkflowCode = ({ clientNr, explorerId, productName, workflowName, codeType}) => {
  const [explorer, setExplorer] = useState([]);
  const [requestBodyFields, setRequestBodyFields] = useState({});
  const [generatedCode, setGeneratedCode] = useState('');
  const [ApiList, setApiList] = useState([]);
  const { isADarkTheme } = useTheme()

  const handleCopy = () => {
    toast.success('Code copied!', {autoClose: 1000});
  };

  const handleRequestBodyChange = (field, value) => {
    // Update the state with the new value for the specified field
    setRequestBodyFields((prevFields) => ({
      ...prevFields,
      [field]: value,
    }));
  };

  useEffect(() => {
      fetchApi();
  }, [clientNr, explorerId, productName, workflowName, codeType]);

  const fetchApi = async () => {
    try {
      const myApibody =
      {
        clientNr: clientNr,
        explorerId: explorerId,
        workflowName:workflowName
      }
      const response = await axios.post(process.env.REACT_APP_CENTRAL_BACK + "/link/queryorderedapi", encodebody(myApibody));
      const myApiList = getDecodedBody(response.data);

    

      generateCode(myApiList)


      setApiList(myApiList);

      const myExplorerbody =
      {
        clientNr: clientNr,
        explorerId: explorerId
      }
      const Eresponse = await axios.post(process.env.REACT_APP_CENTRAL_BACK + "/explorer/query", encodebody(myExplorerbody));
      const myExplorer = getDecodedBody(Eresponse.data);
      setExplorer(myExplorer);

    } catch (error) {
      // Handle any errors
      console.error(error);
    }
  };

  const generateCode = (apis) => {
  let combinedPythonCode = `# Python code for:
# Workflow Name: ${workflowName}
`;
  let combinedJavaScriptCode = `// JavaScript code for:
// Workflow Name: ${workflowName}
`;

  for (const api of apis) {
    if (Object.keys(api).length > 0) {
      if (codeType === "python") {
        const pythonCode = generatePythonCode(api.api, explorer, requestBodyFields);
        combinedPythonCode += pythonCode;
      } else if (codeType === "javascript") {
        const javascriptCode = generateJavaScriptCode(api.api, explorer, requestBodyFields);
        combinedJavaScriptCode += javascriptCode;
      }
    }
  }

  // Determine the combined code based on the selected codeType
  const combinedCode = codeType === "python" ? combinedPythonCode : combinedJavaScriptCode;

  // Set the combined code using setGeneratedCode
  setGeneratedCode(combinedCode);
};

  return (
    <>
      <div className="section-right__toolbar-container section-right__toolbar-container--empty" />
      <div className="section-right__content">
        <div className="section-right__syntax-highlighter">
        <SyntaxHighlighter code={generatedCode} darkMode={isADarkTheme} />
        </div>
      </div>
    </>
  );
};

export default WorkflowCode;
