import { useRef, useContext } from "react";
import { useHistory } from "react-router";
import axios from "axios";
import { AuthContext } from "../../context/AuthContext";
import { Button, Card, Form, InputPassword, InputText, LayoutTextAndContent } from "../../UI";
import Topbar from "../../components/topbar/Topbar";
import {encodebody,getDecodedBody} from "../../utils/utils.js";
import "./updateuser.css";

export default function Updateuser() {
  const { user: currentUser } = useContext(AuthContext);
  const clientNr = currentUser.clientNr;

  const chatbotKey = useRef();
  const username = useRef();
  const email = useRef();
  const password = useRef();
  const passwordAgain = useRef();
  const history = useHistory();

  const handleClick = async (e) => {
    e.preventDefault();
    if (passwordAgain.current.value !== password.current.value) {
      passwordAgain.current.setCustomValidity("Passwords don't match!");
    } else {
      const user = {
        clientNr: clientNr,
        chatbotKey: chatbotKey.current.value,
        username: username.current.value,
        email: email.current.value,
        password: password.current.value,
      };
      const body = encodebody(user);
      try {
        await axios.post(process.env.REACT_APP_CENTRAL_BACK + "/users/update", encodebody(body));
        alert("User has been updated");
        history.push("/");
      } catch (error) {
        alert("Error during update operation: " + (error.response ? JSON.stringify(getDecodedBody(error.response.data)) : error.message));
      }
    }
  };

  return (
    <>
    <LayoutTextAndContent>
      <LayoutTextAndContent.NavbarSlot>
        <Topbar />
      </LayoutTextAndContent.NavbarSlot>
      <LayoutTextAndContent.Cols>
        <LayoutTextAndContent.Col>
          <LayoutTextAndContent.Heading>
            GWOCU Studio Chat
          </LayoutTextAndContent.Heading>
          <LayoutTextAndContent.Description>
            Change your password regularly for more security.
          </LayoutTextAndContent.Description>
        </LayoutTextAndContent.Col>
        <LayoutTextAndContent.Col>
          <Card className="update-user-form-container" shadow={true}>
            <Form onSubmit={handleClick}>
              <Form.Col>
                <InputText
                  ref={chatbotKey}
                  placeholder="Chatbot Key"
                  value={currentUser.chatbotKey}
                  className="updateuserInput"
                  readOnly
                  />
                <InputText
                  ref={username}
                  value={currentUser.username}
                  placeholder="Username"
                  readOnly
                  />
                <InputText
                  ref={email}
                  placeholder="Email"
                  type="email"
                  value={currentUser.email}
                  readOnly
                  />
                <InputPassword
                  ref={password}
                  placeholder="New Password"
                  minLength="6"
                  autoComplete="new-password"
                  required
                  />
                <InputPassword
                  ref={passwordAgain}
                  placeholder="New Password Again"
                  required
                />
              </Form.Col>
              <Form.Col>
                <Button type="submit">Change Password</Button>
                <Button color="secondary" onClick={() => history.push("/")}>
                  Cancel
                </Button>
              </Form.Col>
            </Form>
          </Card>
        </LayoutTextAndContent.Col>
      </LayoutTextAndContent.Cols>
    </LayoutTextAndContent>
    </>
  );
}
