import React, { useState, useEffect } from "react";
import axios from "axios";
import { Button, Form, InputText, Modal, Textarea } from "../../UI";
import {encodebody, getDecodedBody} from "../../utils/utils.js";

function Modalproduct({ clientNr, open, explorerId, onClose }) {
  const [products, setProducts] = useState([]);
  const [selectedSequence, setSelectedSequence] = useState("1");
  const [selectedProductName, setSelectedProductName] = useState("No Name Yet");
  const [selectedProductDescription, setSelectedProductDescription] = useState("No Description Yet");

  useEffect(() => {

  }, []); // Empty dependency array ensures the effect runs only once after the initial render

  const handleNameChange = (event) => {
    setSelectedProductName(event.target.value);
  };

  const handleDescriptionChange = (event) => {
    setSelectedProductDescription(event.target.value)
    }

  const handleSequenceChange = (event) => {
    const inputValue = event.target.value;

    // Check if the input is a valid number
    if (/^\d+$/.test(inputValue) || inputValue === '') {
      // If it's a valid number or an empty string, update the state
      setSelectedSequence(inputValue);
    }
    // If it's not a valid number, you can choose to do nothing or provide feedback to the user
    // For example, show an error message or prevent further action
  };

  const handleSave = async (e) => {
    e.preventDefault();
    // Perform save logic with selectedSource and selectedTarget
    // You can use these values to update your backend or state, as needed
    if (await handleCreateProduct(selectedProductName,selectedProductDescription, selectedSequence))
    {
      onClose();
    }
  };

  async function handleCreateProduct(productName, productDescription, sequence) {
    try {
      const mypayload = {
        clientNr: clientNr,
        explorerId: explorerId,
        productName: productName,
        sequence: sequence,
        description: productDescription,
        status: "Private",
      };

      const response = await axios.post(process.env.REACT_APP_CENTRAL_BACK + "/product/register", encodebody(mypayload));
      const responseData = getDecodedBody(response.data);
      // Check if the response indicates an error
      if (responseData && responseData.error) {
        // Display an alert with the error data
        alert(`Error: ${responseData.error}`);
        return false;
      }

      // setNodesAdded(nodesAdded + 1);
      return true;
    } catch (error) {
      // Handle unexpected errors (e.g., network issues)
      console.error("An unexpected error occurred:", error);
      alert("An unexpected error occurred. Please try again.");
      return false;
    }
  };

  return (
    <Modal open={open} onClose={onClose} maxWidth="60rem">
      <Modal.Title>Add New Product</Modal.Title>
      <Modal.Body>
        <Form onSubmit={handleSave}>
          <Form.Control>
            <Form.Label htmlFor="productName">Product Name</Form.Label>
            <InputText
              id="productName"
              value={selectedProductName}
              onChange={handleNameChange}
            />
          </Form.Control>
          <Form.Control>
            <Form.Label htmlFor="sequence">Sequence in product tree</Form.Label>
            <InputText
              id="sequence"
              value={selectedSequence}
              onChange={handleSequenceChange}
            />
          </Form.Control>
          <Form.Control>
            <Form.Label htmlFor="productDescription">Description</Form.Label>
            <Textarea
              id="productDescription"
              value={selectedProductDescription}
              onChange={handleDescriptionChange}
              rows="5"
            />
          </Form.Control>
          <Form.Row>
            <Button color="secondary" onClick={onClose}>Cancel</Button>
            <Button type="submit">Add Product</Button>
          </Form.Row>
        </Form>
      </Modal.Body>
    </Modal>
  );
}

export default Modalproduct;
