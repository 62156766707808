import ReactSyntaxHighlighter from "react-syntax-highlighter";
import {
  stackoverflowLight as light,
  dark,
} from "react-syntax-highlighter/dist/esm/styles/hljs";
import styled from "styled-components";

const StyledContainer = styled.div`
  height: 100%;
  width: 100%;

  & pre {
    height: 100%;
    border-radius: 0.25em;
    overflow-x: auto;
    white-space: pre-wrap;
    word-wrap: break-word;
  }
`;

/**
 * Gwocu's component for rendering syntax-highlighted code blocks.
 *
 * This component takes up the full height and width of its parent component.
 *
 * @component
 * @param {Object} props - The component's props.
 * @param {string} props.code - The code to be highlighted, passed as a plain string.
 * @param {boolean} [props.darkMode=true] - If `true`, the syntax highlighter uses a dark theme. Default value: `true`.
 * @param {string} [props.language="javascript"] - The programming language of the code. Supports various languages, to see the full list, [click here](https://github.com/react-syntax-highlighter/react-syntax-highlighter/blob/HEAD/AVAILABLE_LANGUAGES_HLJS.MD), etc. Default value: `"javascript"`.
 * @returns {JSX.Element} The rendered syntax-highlighted code block.
 *
 * @example
 * <SyntaxHighlighter code={`
 *   const message = "Hello, world!";
 *   
 * `}
 *  darkMode={false}
 * />
 */
export const SyntaxHighlighter = ({
  code,
  language = "javascript",
  darkMode = true,
}) => {
  return (
    <StyledContainer>
      <ReactSyntaxHighlighter
        language={language}
        style={darkMode ? dark : light}
      >
        {code}
      </ReactSyntaxHighlighter>
    </StyledContainer>
  );
};
