import React, { useState, useEffect } from 'react';
import axios from 'axios';
import yaml from 'js-yaml';
import { Button, Form, InputText, Modal, RadioButton, Select, Tooltip } from '../../UI';
import { DeleteOutline, EditOutlined } from '@mui/icons-material';
import './embeddingsManager.css';
import { encodebody, getDecodedBody } from '../../utils/utils';
// import { decode } from 'jsonwebtoken';

const EmbeddingsManager = ({ clientNr, explorerId, open, onClose }) => {
  const [collections, setCollections] = useState([]);
  const [selectedCollection, setSelectedCollection] = useState(null);
  const [embeddings, setEmbeddings] = useState([]);
  const [filters, setFilters] = useState({ keyword: '', source: '', heading: '', textquery: '' });
  const [filterType, setFilterType] = useState('filters'); // 'filters' or 'textquery'
  const [newEmbedding, setNewEmbedding] = useState({ keywords: '', sources: '', heading: '', text: '' });
  const [showCreateEmbedding, setShowCreateEmbedding] = useState(false);
  const [editingEmbedding, setEditingEmbedding] = useState(null);
  const [loading, setLoading] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const [importing, setImporting] = useState(false);
  const [exporting, setExporting] = useState(false); 
  const vectorDBClient = process.env.REACT_APP_HOST_VECTORDB_CLIENT;

  const handleExport = async () => {
    try {
      setExporting(true);
      const response = await axios.post(`${process.env.REACT_APP_HOST_VECTORDB_BACK}/api/embedding/query`, {
        clientNr: vectorDBClient,
        collectionId: selectedCollection,
      });

      // Extract only the required properties
      const filteredData = response.data.map(({ keywords, sources, heading, text }) => ({
        keywords,
        sources,
        heading,
        text
      }));

      const yamlContent = yaml.dump(filteredData);
      const blob = new Blob([yamlContent], { type: 'application/x-yaml' });
      saveAs(blob, 'embeddings.yaml');
    } catch (error) {
      console.error('Error exporting embeddings:', error);
    } finally {
      setExporting(false);
    }
  };

  const handleFileSelect = (event) => {
    setSelectedFile(event.target.files[0]);
  };

  const handleImport = async () => {
    if (!selectedFile) {
      alert("Please select a file first."); //
      return;
    }

    setImporting(true);
    const reader = new FileReader();
    reader.onload = async (e) => {
      try {
        const yamlContent = yaml.load(e.target.result);
        let importedCount = 0;
        let skippedCount = 0;

        for (const obj of yamlContent) {
          if (typeof obj.text === 'string' && obj.text.trim() !== '') {
            const embeddingData = {
              keywords: Array.isArray(obj.keywords) ? obj.keywords : (obj.keywords ? [obj.keywords] : []),
              sources: Array.isArray(obj.sources) ? obj.sources : (obj.sources ? [obj.sources] : []),
              heading: typeof obj.heading === 'string' ? obj.heading : '',
              text: obj.text
            };

            await axios.post(`${process.env.REACT_APP_HOST_VECTORDB_BACK}/api/embedding/register`, { 
              clientNr: vectorDBClient,
              collectionId: selectedCollection,
              ...embeddingData
            });
            importedCount++;
          } else {
            skippedCount++;
          }
        }

        fetchEmbeddings();
        setSelectedFile(null); // Reset selected file
        alert(`Import completed. ${importedCount} embeddings imported, ${skippedCount} skipped.`);
      } catch (error) {
        console.error('Error importing embeddings:', error);
        alert("Error importing embeddings. Please check the console for details.");
      } finally {
        setImporting(false);
      }
    };

    reader.readAsText(selectedFile);
  };

  useEffect(() => {
    fetchCollections();
  }, [clientNr, explorerId]);

  const fetchCollections = async () => {
    try {
      const response = await axios.post(`${process.env.REACT_APP_CENTRAL_BACK}/workspace/collections`, encodebody({ clientNr: clientNr, explorerId: explorerId }));
      const responseData = getDecodedBody(response.data);
      setCollections(responseData);
      setSelectedCollection(responseData[0]);
    } catch (error) {
      console.log('Error fetching collections:', error);
    }
  };

  const fetchEmbeddings = async () => {
    try {
      setLoading(true);
      const query = filterType === 'filters' ? { ...filters, textquery: '' } : { keyword: '', source: '', heading: '', textquery: filters.textquery };
     
      const response = await axios.post(`${process.env.REACT_APP_HOST_VECTORDB_BACK}/api/embedding/query`, { clientNr: vectorDBClient, collectionId: selectedCollection, ...query });
      setEmbeddings(response.data);
      setLoading(false);
    } catch (error) {
      
      setLoading(false);
    }
  };

  const handleFilterChange = (e) => {
    const { name, value } = e.target;
    setFilters((prev) => ({ ...prev, [name]: value }));
  };

  const handleNewEmbeddingChange = (e) => {
    const { name, value } = e.target;
    setNewEmbedding((prev) => ({ ...prev, [name]: value }));
  };

  const handleRegisterEmbedding = async () => {
    try {
      setLoading(true);
      const embeddingData = {
        ...newEmbedding,
        keywords: newEmbedding.keywords.split(',').map(item => item.trim()),
        sources: newEmbedding.sources.split(',').map(item => item.trim())
      };
      await axios.post(`${process.env.REACT_APP_HOST_VECTORDB_BACK}/api/embedding/register`, { clientNr: vectorDBClient, collectionId: selectedCollection, ...embeddingData });
      fetchEmbeddings();
      setNewEmbedding({ keywords: '', sources: '', heading: '', text: '' }); // Clear fields
      setShowCreateEmbedding(false); // Hide fields and button
    } catch (error) {
      console.error('Error registering embedding:', error);
    } finally {
      setLoading(false);
    }
  };

  const handleRegisterEmbeddingCancel = async () => {
    setNewEmbedding({ keywords: '', sources: '', heading: '', text: '' }); // Clear fields
    setShowCreateEmbedding(false); // Hide fields and button
  }

  const handleEditEmbeddingChange = (e) => {
    const { name, value } = e.target;
    setEditingEmbedding((prev) => ({ ...prev, [name]: value }));
  };

  const handleUpdateEmbedding = async () => {
    try {
      setLoading(true);
      const { _id, keywords, sources, ...updatedData } = editingEmbedding;
      const embeddingData = {
        ...updatedData,
        keywords: keywords.split(',').map(item => item.trim()),
        sources: sources.split(',').map(item => item.trim())
      };
      await axios.post(`${process.env.REACT_APP_HOST_VECTORDB_BACK}/api/embedding/update`, { clientNr, collectionId: selectedCollection, embeddingId: _id, ...embeddingData });
      fetchEmbeddings();
      setEditingEmbedding(null); // Hide input fields
    } catch (error) {
      console.error('Error updating embedding:', error);
      setLoading(false);
    } finally {
      setLoading(false);
    }
  };

  const handleUpdateEmbeddingCancel = async () => {
    setEditingEmbedding(null); // Hide input fields
  }

  const handleDeleteEmbedding = async (embeddingId) => {
    try {
      await axios.post(`${process.env.REACT_APP_HOST_VECTORDB_BACK}/api/embedding/delete`, { clientNr:vectorDBClient, collectionId: selectedCollection, embeddingId });
      fetchEmbeddings();
    } catch (error) {
      console.error('Error deleting embedding:', error);
    }
  };

  const handleDeleteAllEmbeddings = async () => {
    try {
      await axios.post(`${process.env.REACT_APP_HOST_VECTORDB_BACK}/api/embedding/deleteall`, { clientNr:vectorDBClient, collectionId: selectedCollection });
      fetchEmbeddings();
    } catch (error) {
      console.error('Error deleting all embeddings:', error);
    }
  };

  const handleEditEmbeddingClick = (embedding) => {
    setEditingEmbedding({
      ...embedding,
      keywords: embedding.keywords.join(', '),
      sources: embedding.sources.join(', ')
    });
  };

  return (
    <Modal open={open} onClose={onClose} maxWidth="72rem">
      <Modal.Title>Embeddings Manager</Modal.Title>
      <Modal.Body>
        <Form>
          <Form.Col>
            <Select
              value={selectedCollection || ''}
              onChange={(e) => setSelectedCollection(e.target.value)}
              options={collections.map((collection) => ({label: collection, value: collection}))}
            />
          <input
            type="file"
            onChange={handleFileSelect}
            style={{ display: 'none' }}
            id="file-input"
            />
          <Form.Row>
          {selectedFile
            ? (
                <Button onClick={handleImport} disabled={importing}>
                  {importing ? "Importing..." : "Import"}
                </Button>
            ) : (
                <Button onClick={() => document.getElementById('file-input').click()}>
                  Select File
                </Button>
            )}
            <Button onClick={handleExport} disabled={exporting}>
              {exporting ? "Exporting..." : "Export"}
            </Button>
          </Form.Row>
          </Form.Col>
        </Form>
        {showCreateEmbedding && (
          <section className="embeddings-manager__section">
            <Form>
              <Form.Control>
                <Form.Label>New Embedding</Form.Label>
                <Form.Col>
                  <InputText
                    name="keywords"
                    placeholder="Keywords (comma-separated)"
                    value={newEmbedding.keywords}
                    onChange={handleNewEmbeddingChange}
                  />
                  <InputText
                    name="sources"
                    placeholder="Sources (comma-separated)"
                    value={newEmbedding.sources}
                    onChange={handleNewEmbeddingChange}
                  />
                  <InputText
                    name="heading"
                    placeholder="Heading"
                    value={newEmbedding.heading}
                    onChange={handleNewEmbeddingChange}
                  />
                  <InputText
                    name="text"
                    placeholder="Text"
                    value={newEmbedding.text}
                    onChange={handleNewEmbeddingChange}
                  />
                </Form.Col>
                <Form.Row>
                  <Button color="secondary" onClick={handleRegisterEmbeddingCancel}>Cancel</Button>
                  <Button onClick={handleRegisterEmbedding}>
                    {loading ? "Vectorizing..." : "Save"}
                  </Button>
                </Form.Row>
              </Form.Control>
            </Form>
          </section>
        )}
        <section className="embeddings-manager__section">
          <Form>
            <Form.Col>
              <RadioButton
                name="filterType"
                value="filters"
                checked={filterType === "filters"}
                onChange={() => setFilterType("filters")}
              >
                Filter by Keyword, Source, and Heading
              </RadioButton>
              <RadioButton
                name="filterType"
                value="textquery"
                checked={filterType === "textquery"}
                onChange={() => setFilterType("textquery")}
              >
                Filter by Text Query
              </RadioButton>
            </Form.Col>
            <Form.Col>
            {filterType === "filters" ? (
              <>
                <InputText
                  name="keyword"
                  placeholder="Keyword"
                  value={filters.keyword}
                  onChange={handleFilterChange}
                />
                <InputText
                  name="source"
                  placeholder="Source"
                  value={filters.source}
                  onChange={handleFilterChange}
                />
                <InputText
                  name="heading"
                  placeholder="Heading"
                  value={filters.heading}
                  onChange={handleFilterChange}
                />
              </>
            ) : (
              <Form.Row>
                <InputText
                  name="textquery"
                  placeholder="Text Query"
                  value={filters.textquery}
                  onChange={handleFilterChange}
                />
              </Form.Row>
            )}
              <Form.Row>
                <Button onClick={fetchEmbeddings}>{loading ? "Filtering..." : "Filter Embeddings"}</Button>
                <Button color="secondary" onClick={() => setShowCreateEmbedding(true)}>Add Embedding</Button>
                <Button color="danger" onClick={handleDeleteAllEmbeddings}>Delete All</Button>
              </Form.Row>
            </Form.Col>
          </Form>
        </section>
        {editingEmbedding && (
          <section className="embeddings-manager__section">
            <Form>
              <Form.Control>
                <Form.Label>Edit Embedding</Form.Label>
                <InputText
                  name="keywords"
                  placeholder="Keywords (comma-separated)"
                  value={editingEmbedding?.keywords || ""}
                  onChange={handleEditEmbeddingChange}
                />
                <InputText
                  name="sources"
                  placeholder="Sources (comma-separated)"
                  value={editingEmbedding?.sources || ""}
                  onChange={handleEditEmbeddingChange}
                />
                <InputText
                  type="text"
                  name="heading"
                  placeholder="Heading"
                  value={editingEmbedding?.heading || ""}
                  onChange={handleEditEmbeddingChange}
                />
                <InputText
                  type="text"
                  name="text"
                  placeholder="Text"
                  value={editingEmbedding?.text || ""}
                  onChange={handleEditEmbeddingChange}
                />
                <Form.Row>
                  <Button color="secondary" onClick={handleUpdateEmbeddingCancel}>Cancel</Button>
                  <Button onClick={handleUpdateEmbedding} disabled={loading}>
                    {loading ? "Saving..." : "Save Changes"}
                  </Button>
                </Form.Row>
              </Form.Control>
            </Form>
          </section>
        )}
        <section className="embeddings-manager__section">
          <h3 className="embeddings-manager__section-title">Embeddings</h3>
          <div className="embeddings-manager__table-container">
            <table className="embeddings-manager__table">
              <thead>
                  <tr>
                  <th>Keywords</th>
                  <th>Sources</th>
                  <th>Heading</th>
                  <th>Text</th>
                  <th className="actions-column">Actions</th>
                  </tr>
              </thead>
              <tbody>
                {embeddings.map((embedding) => (
                <tr key={embedding._id}>
                    <td>{embedding.keywords.join(', ')}</td>
                    <td>{embedding.sources.join(', ')}</td>
                    <td>{embedding.heading}</td>
                    <td className="text-column">{embedding.text}</td>
                    <td className="actions-column">
                      <Tooltip tooltip={{content: "Edit embedding", isHtml: false}}>
                        <Button
                          color="secondary"
                          onClick={() => handleEditEmbeddingClick(embedding)}
                          size="sm"
                          rounded
                          >
                          <EditOutlined />
                        </Button>
                      </Tooltip>
                      <Tooltip tooltip={{content: "Delete embedding", isHtml: false}}>
                        <Button
                          color="danger"
                          onClick={() => handleDeleteEmbedding(embedding._id)}
                          size="sm"
                          rounded
                        >
                          <DeleteOutline />
                        </Button>
                      </Tooltip>
                    </td>
                </tr>
                ))}
              </tbody>
            </table>
          </div>
        </section>
      </Modal.Body>
    </Modal>
  );
};

export default EmbeddingsManager;

