import styled from "styled-components"

const StyledCard = styled.article`
  padding: 1.6rem;
  border-radius: 0.8rem;
  background-color: var(--card-bg-clr);
  width: 100%;
  box-shadow: ${({$shadow}) => $shadow ? `var(--card-box-shadow)` : "unset"};
`;

/**
 * Gwocu's card component
 *
 * @component
 * @param {Object} props - The component's props
 * @param {React.ReactNode} props.children - The content in the component. Default value: `undefined`.
 * @param {string} [props.className="undefined] - Adds custom className for the component. Default value: `undefined`.
 * @param {boolean} [props.shadow=false] - If `true`, adds box-shadow for the component. Default value: `false`.
 * @returns {JSX.Element} The rendered card component.
 *
 * @example
 * <Card shadow="true">
 *   <h1>I'm a card</h1>
 * </Card>
 */
export const Card = ({children, className, shadow = false}) => {
  const classes = `card ${className || ""}`;
  return (
    <StyledCard className={classes} $shadow={shadow}
      >
      {children}
    </StyledCard>
  )
}