import React, { useState, useEffect } from "react";
import axios from "axios";
import { Button, Form, InputText, Modal, Select } from "../../UI";
import {encodebody, getDecodedBody} from "../../utils/utils.js";

function Modalworkflowclone({ clientNr, explorerId, open, onClose, sourceWorkflowName, SourceProductName }) {
  const [products, setProducts] = useState([]);
  const [selectedDestinationProduct, setSelectedDestinationProduct] = useState(null);
  const [selectedWorkflowCloneName, setSelectedWorkflowCloneName] = useState("No Name Yet");

  useEffect(() => {
    if(!open) return;
    const fetchData = async () => {
      // first query products for products listbox
      const myProductsPayload = {
        clientNr: clientNr,
        explorerId: explorerId,
      }
      try {
        const productresponse = await axios.post(process.env.REACT_APP_CENTRAL_BACK + "/product/queryall", encodebody(myProductsPayload));
        const productresponseData = getDecodedBody(productresponse.data);
        setProducts(productresponseData);

        if (productresponseData.length > 0) {
          setSelectedDestinationProduct(productresponseData[0].productName);
        }

      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, [open]); // Empty dependency array ensures the effect runs only once after the initial render

  const handleNameChange = (event) => {
    setSelectedWorkflowCloneName(event.target.value);
  };

  const handleClone = async () => {
    // Perform save logic with selectedSource and selectedTarget
    // You can use these values to update your backend or state, as needed
    if (await handleCloneWorkflow(selectedDestinationProduct, selectedWorkflowCloneName))
    {
      alert("Workflow was succesfully cloned!")
      window.location.reload();
      onClose();
    }
  };

  async function handleCloneWorkflow(DestinationProductName, workflowCloneName) {
    try {
      const mypayload = {
        clientNr: clientNr,
        explorerId: explorerId,
        productName: SourceProductName,
        destinationProductName: DestinationProductName,
        name: sourceWorkflowName,
        newName: workflowCloneName
      };

      const response = await axios.post(process.env.REACT_APP_CENTRAL_BACK + "/workflow/cloneworkflow", encodebody(mypayload));
      const responseData = getDecodedBody(response.data);
      // Check if the response indicates an error
      if (responseData && responseData.error) {
        // Display an alert with the error data
        alert(`Error: ${responseData.error}`);
        return false;
      }

      // setNodesAdded(nodesAdded + 1);
      return true;
    } catch (error) {
      // Handle unexpected errors (e.g., network issues)
      console.error("An unexpected error occurred:", error);
      alert("An unexpected error occurred. Please try again.");
      return false;
    }
  };

  return (
    <Modal open={open} onClose={onClose} maxWidth="48rem">
      <Modal.Title>Clone Workflow</Modal.Title>
      <Modal.Body>
        <Form>
          <Form.Control>
            <Form.Label htmlFor="products">Destination Product folder</Form.Label>
            <Select
              id="products"
              value={selectedDestinationProduct}
              onChange={(e) => setSelectedDestinationProduct(e.target.value)}
              options={products.map(({productName}) => ({label: productName, value: productName}))}
            />
          </Form.Control>
          <Form.Control>
            <Form.Label htmlFor="workflowName">Workflow Clone Target Name</Form.Label>
            <InputText
              id="workflowName"
              value={selectedWorkflowCloneName}
              onChange={handleNameChange}
            />
          </Form.Control>
          <Form.Row>
            <Button color="secondary" onClick={onClose}>Cancel</Button>
            <Button onClick={handleClone}>Clone</Button>
          </Form.Row>
        </Form>
      </Modal.Body>
    </Modal>
  );
}


export default Modalworkflowclone;
