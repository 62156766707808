import React, { useState, useContext } from "react";
import { useHistory } from 'react-router-dom';
import axios from "axios";
import PasswordValidator from 'password-validator';
import { AuthContext } from "../../context/AuthContext";
import { Button, Form, InputPassword, Modal } from "../../UI";
import { encodebody, getDecodedBody } from "../../utils/utils.js";

function Modalacceptinvite({ clientNr, explorerId, onClose }) {

  const schema = new PasswordValidator();
  schema
    .is().min(8)                                    // Minimum length 8
    .is().max(100)                                  // Maximum length 100
    .has().uppercase()                              // Must have uppercase letters
    .has().lowercase()                              // Must have lowercase letters
    .has().digits(2)                                // Must have at least 2 digits
    .has().symbols()                                // Must have symbols
    .has().not().spaces();

  const { user } = useContext(AuthContext);

  const [password, setPassword] = useState('');
  const [client, setClient] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [userdata, setUserdata] = useState({});
  const history = useHistory();
  const params = new URLSearchParams(window.location.search);
  const token = params.get('token');


  function handleOnClose() {
    // Attempt to close the current window
    window.close();
  }

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
  };

  const handleConfirmPasswordChange = (e) => {
    setConfirmPassword(e.target.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (password !== confirmPassword) {
      alert('Passwords do not match');
      return;
    }

    const result = schema.validate(password, { list: true });
    if (result.length === 0) {
     
    } else {
      alert('Your password is not strong enough, please make sure it is at least 8 characters long, has no spaces, contains uppercase and lower case letters, has symbols and has at least two digits.'); // Lists aspects of the password that failed
      return
    }

    // verify token and return JSON
    try {
    const tokenresponse = await axios.post(process.env.REACT_APP_CENTRAL_BACK + '/invitation/verifytoken', {token:token});
    var myuserData = tokenresponse.data
    setClient(myuserData.clientNr);

    }
    catch(error) {
      alert("Your token is either invalid or expired or your login has already been created. Token expires after 5 days")
      return
    }

    // Send data to your API endpoint to create the user
    try {
      const userPayload =
      {
        clientNr: myuserData.clientNr,
        email: myuserData.toEmail,
        username: myuserData.toEmail,
        password: password,
        chatbotKey: myuserData.chatbotKey,
        groups: ["chatbotDesigners"],
        explorers: myuserData.explorers,
        isAdmin: false

      }
    
      await axios.post(process.env.REACT_APP_CENTRAL_BACK + '/auth/register', encodebody(userPayload));
      // Redirect to login page after successful registration
      alert("User was created successfully! Please login.")
      // delete invite
      await axios.post(process.env.REACT_APP_CENTRAL_BACK + "/invitation/delete", encodebody({ chatbotKey: myuserData.chatbotKey, email:  myuserData.toEmail}));

      history.push(`/login?clientNr=${encodeURIComponent(myuserData.clientNr)}&chatbotKey=${encodeURIComponent(myuserData.chatbotKey)}&email=${encodeURIComponent(myuserData.email)}`);
      // history.push('/login');
    } catch (err) {
      if (err.response) {
        // The request was made and the server responded with a status code that is not in the range of 2xx
        alert(`Failed to create user: ${getDecodedBody(err.response.data)}`);
        return
      } else if (err.request) {
        // The request was made but no response was received
        alert("Failed to create user: No response from server");
        return
      } else {
        // Something happened in setting up the request that triggered an Error
        alert(`Failed to create user: ${err.message}`);
      }
    }
  };

  return (
    <Modal open={true} onClose={handleOnClose} maxWidth="40rem">
      <Modal.Title>Welcome to the {client} GWOCU Studio workspace</Modal.Title>
      <Modal.Body>
        <Form>
          <Form.Row>
            <p>Please set your password.</p>
          </Form.Row>
          <Form.Control>
            <Form.Label htmlFor="password">Password</Form.Label>
            <InputPassword
              id="password"
              value={password}
              onChange={handlePasswordChange}
            />
          </Form.Control>
          <Form.Control>
            <Form.Label htmlFor="confirmpassword">Confirm password</Form.Label>
            <InputPassword
              type="password"
              id="confirmpassword"
              value={confirmPassword}
              onChange={handleConfirmPasswordChange}
            />
          </Form.Control>
          <Form.Row>
            <Button color="secondary" onClick={handleOnClose}>Cancel</Button>
            <Button type="submit" onClick={handleSubmit}>Submit</Button>
          </Form.Row>
        </Form>
      </Modal.Body>
    </Modal>
  );
}


export default Modalacceptinvite;
