import { useState, useContext, useEffect } from "react";
import { useLocation, useHistory } from "react-router-dom";
import axios from 'axios';
import { AuthContext } from "../../context/AuthContext";
import { loginCall } from "../../apiCalls";
import { Button, Card, Form, InputText, InputPassword, LayoutTextAndContent, Spinner, Select, Tooltip } from "../../UI";
import { getDecodedBody } from "../../utils/utils.js";
import tooltips from '../../tooltips/tooltips';
import "./login.css";


const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_CENTRAL_BACK,
  validateStatus: function (status) {
    return status < 500; // Resolve only if the status code is less than 500
  }
});

export default function Login() {
 

  const location = useLocation();  // Hook to get the location object
  const isLoginRoute = location.pathname === '/login';
  const params = new URLSearchParams(location.search);  // Instantiate with the current query string

  const clientNrFromQuery = params.get('clientNr');
  const chatbotKeyFromQuery = params.get('chatbotKey');
  const emailFromQuery = params.get('email');

  const [clientNr, setClientNr] = useState(clientNrFromQuery || "");
  const [chatbotKey, setChatbotKey] = useState(chatbotKeyFromQuery || "");
  const [email, setEmail] = useState("");
  const [gwokenToken, setGwokenToken] = useState(process.env.REACT_APP_GWOKUTOKEN || "");
  const [explorers, setExplorers] = useState([]);
  const [explorerSelect, setExplorerSelect] = useState("");
  const [password, setPassword] = useState("");
  const { isFetching, dispatch } = useContext(AuthContext);

  const history = useHistory();

  const handleClick = async (e) => {
    
    e.preventDefault();

    await loginCall(
      {
        clientNr,
        explorer: explorerSelect,
        email,
        password,
      },
      dispatch
    );

   
    if (isLoginRoute)
    {
      history.push("/");
    }
  };


  useEffect(() => {

    const fetchExplorerData = async () => {
      // Check if all required fields have values before making the API call
      if (clientNr.trim() !== "" && email.trim() !== "") {
        const body = {
          clientNr,
          email,
        };
        try {

          const response = await axios.post(
            process.env.REACT_APP_CENTRAL_BACK + "/users/explorers", body
          );
          // Assuming the API response has an array of explorers in the 'data' property
          const myExplorers = getDecodedBody(response.data)
          setExplorers(myExplorers);
          if (myExplorers.length > 0)
          {
          setExplorerSelect(myExplorers[0].id);
          }
          else
          {
            setExplorerSelect("");
          }
        } catch (error) {
          setExplorers([]);
        }
      }
    };

    fetchExplorerData();
  }, [clientNr, chatbotKey, email,gwokenToken]);

  return (
    <LayoutTextAndContent>
      <LayoutTextAndContent.Cols>
        <LayoutTextAndContent.Col>
          <LayoutTextAndContent.Heading>
            GWOCU Studio
          </LayoutTextAndContent.Heading>
          <LayoutTextAndContent.Description>
            Part of the GWOCU suite of products.
          </LayoutTextAndContent.Description>
        </LayoutTextAndContent.Col>
        <LayoutTextAndContent.Col>
          <Card className="login-form-container" shadow={true}>
            <Form onSubmit={handleClick}>
              <Form.Col>
                <Tooltip tooltip={tooltips.clientNr} placement="left">
                  <InputText
                    placeholder="Client"
                    value={clientNr}
                    onChange={(e) => setClientNr(e.target.value)}
                    required
                  />
                </Tooltip>
                <Tooltip tooltip={tooltips.email} placement="left">
                  <InputText
                    placeholder="Email"
                    type="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                </Tooltip>
                <Tooltip tooltip={tooltips.password} placement="left">
                  <InputPassword
                    placeholder="Password"
                    minLength="6"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    required
                  />
                </Tooltip>
              </Form.Col>
              <Form.Col>
                <Button type="submit" disabled={isFetching}>
                  {isFetching ? (
                      <>
                        <Spinner thickness={7} />
                        Signing In...
                      </>
                    ) : (
                      "Sign In"
                  )}
                </Button>
              </Form.Col>
            </Form>
          </Card>
        </LayoutTextAndContent.Col>
      </LayoutTextAndContent.Cols>
    </LayoutTextAndContent>
  );
}
