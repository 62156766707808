import React, { useState } from 'react';
import axios from 'axios';
import jsYaml from 'js-yaml';
import { Button, FileInput, Form, Icon, Modal, ProgressBar } from '../../UI';
import { encodebody, getDecodedBody } from "../../utils/utils.js";
import HelpCenterIcon from '@mui/icons-material/HelpCenter';

const ImportProduct = ({ targetClientNr, targetExplorerId, open, onClose }) => {
  const existingProducts = new Set();
  const workflowsSet = new Set();
  const [file, setFile] = useState(null);
  const [progress, setProgress] = useState(0);

  const handleFileChange = (event) => {
    setFile(event.target.files[0]);
  };

  const cleanObject = (obj) => {
    const newObj = { ...obj };
    delete newObj._id;
    delete newObj.__v;
    delete newObj.createdAt;
    delete newObj.updatedAt;
    newObj.clientNr = targetClientNr;
    newObj.explorerId = targetExplorerId;
    return newObj;
  };

  const cleanLink = (obj) => {
    const newObj = { ...obj };
    delete newObj._id;
    delete newObj.__v;
    delete newObj.createdAt;
    delete newObj.updatedAt;
    return newObj;
  };

  const updateProgress = (increment) => {
    setProgress((prevProgress) => prevProgress + increment);
  };

  const handleImport = async () => {
    if (!file) return;

    const reader = new FileReader();
    reader.onload = async (e) => {
      const text = e.target.result;
      const data = jsYaml.load(text);

      const totalSteps = data.products.length + data.workflows.length + data.tasks.length + data.links.length + (data.apis ? data.apis.length : 0);
      const progressIncrement = 100 / totalSteps;

      // Process and register products
      for (const product of data.products) {
        const cleanedProduct = cleanObject(product);

        try {
          await axios.post(process.env.REACT_APP_CENTRAL_BACK + '/product/query', encodebody({ clientNr: targetClientNr, explorerId: targetExplorerId, productName: cleanedProduct.productName }));
          existingProducts.add(cleanedProduct.productName);
        } catch (error) {
          await axios.post(process.env.REACT_APP_CENTRAL_BACK + '/product/register', encodebody(cleanedProduct));
        } finally {
          updateProgress(progressIncrement);
        }
      }

      // Process and register workflows
      for (const workflow of data.workflows) {
        if (!existingProducts.has(workflow.productName)) {
          const cleanedWorkflow = cleanObject(workflow);
          try {
            await axios.post(process.env.REACT_APP_CENTRAL_BACK + '/workflow/register', encodebody(cleanedWorkflow));
            workflowsSet.add(cleanedWorkflow.name);
          } catch (error) {
            console.error(`Error registering workflow `);
          } finally {
            updateProgress(progressIncrement);
          }
        }
      }

      // Process and register tasks
      for (const task of data.tasks) {
        if (workflowsSet.has(task.workflowName)) {
          const cleanedTask = cleanObject(task);
          try {
            await axios.post(process.env.REACT_APP_CENTRAL_BACK + '/task/register', encodebody(cleanedTask));
          } catch (error) {
            console.error(`Error registering task`);
          } finally {
            updateProgress(progressIncrement);
          }
        }
      }

      // Process and register links
     
      for (const link of data.links) {
        if (workflowsSet.has(link.workflowName)) {
         
          if (link.links && Array.isArray(link.links)) {
            for (let i = 0; i < link.links.length; i++) {
              link.links[i] = cleanLink(link.links[i]);
            }
          }
          const cleanedLink = cleanObject(link);
          try {
            await axios.post(process.env.REACT_APP_CENTRAL_BACK + '/link/update', encodebody(cleanedLink));
          } catch (error) {
            console.error(`Error registering link:`, error);
          } finally {
            updateProgress(progressIncrement);
          }
        }
      }
     
      // Process and register APIs
      // if (workflowsSet.size > 0) {
       
        for (const api of data.apis) {
          const cleanedApi = cleanObject(api);
          try {
          
            await axios.post(process.env.REACT_APP_CENTRAL_BACK + '/api/query', encodebody({ clientNr: targetClientNr, explorerId: targetExplorerId, name: cleanedApi.name }));
          } catch (error) {
            try {
            await axios.post(process.env.REACT_APP_CENTRAL_BACK + '/api/register', encodebody(cleanedApi));
           
            }
            catch(err)
            {
              console.log("error while saving api", err)
            }
          } finally {
            updateProgress(progressIncrement);
          }
       //  }
      }
      try{
        await axios.post(process.env.REACT_APP_CENTRAL_BACK + '/folder/sync', encodebody({ clientNr: targetClientNr, explorerId: targetExplorerId}));
       
      }
      catch(err)
      {
        console.log("Could not SYNC apis");
      }
      setProgress(100);
      setTimeout(() => {
        setProgress(0);
        alert("Products imported!");
      }, 500);
    };

    reader.readAsText(file);
  };

  return (
    <Modal open={open} onClose={onClose} maxWidth="48rem">
      <Modal.Title>
        Import Products
        <Icon href="https://wiki.gwocu.com/en/GWOCU-Studio/product-tree-panel-menu#importproducts-section">
          <HelpCenterIcon />
        </Icon>
      </Modal.Title>
      <Modal.Body>
        <Form>
          <Form.Row>
            <p>Important notice: Existing products with the same name will <strong>NOT</strong> be overwritten!</p>
          </Form.Row>
          <Form.Row>
            <FileInput onChange={handleFileChange} />
          </Form.Row>
          {progress > 0 && (
            <Form.Row>
              <ProgressBar progress={progress} />
            </Form.Row>
          )}
          <Form.Row>
            <Button color="secondary" onClick={onClose}>Cancel</Button>
            <Button onClick={handleImport} disabled={!file}>Import products</Button>
          </Form.Row>
        </Form>
      </Modal.Body>
    </Modal>
  );
};

export default ImportProduct;
