import { createContext, useContext, useEffect, useState } from "react";
import { themes } from "../themes";

/**
 * @typedef TThemeContext
 * @property {string} theme - The current theme's dataTheme, (e.g., "default", "classic")
 * @property {boolean} isADarkTheme - Indicates if the current theme is dark.
 * Use it for components that rely on a prop to change its theme (e.g., CodeEditor, SyntaxHighlighter)
 * @property {(dataTheme: string) => void} switchTheme - Function to switch to a new theme.
 */

/** @type {import('react').Context<TThemeContext>} */
const ThemeContext = createContext(null);

// Determines if the current theme is dark based on computed styles
const isCurrentThemeDark = () => {
  const colorScheme = getComputedStyle(document.documentElement).getPropertyValue("--color-scheme");
  return colorScheme.includes("dark");
}

// Retrieves the stored theme from localStorage, or defaults to the first theme
const getStoredTheme = () => {
  const storedDataTheme = localStorage.getItem("theme");
  const themeExists = !!storedDataTheme && themes.find(theme => theme.dataTheme === storedDataTheme);
  return themeExists ? storedDataTheme : themes[0].dataTheme
}

export const ThemeProvider = ({children}) => {
  // Initializes theme based on localStorage or defaults
  const [dataTheme, setDataTheme] = useState(getStoredTheme());

  // Tracks whether the current theme is dark
  const [isADarkTheme, setIsADarkTheme] = useState(true);

  useEffect(() => {
    // Updates the theme by setting the data-theme attribute and storing it
    document.documentElement.setAttribute("data-theme", dataTheme);
    localStorage.setItem("theme", dataTheme)
    setIsADarkTheme(isCurrentThemeDark())
  }, [dataTheme]);

  const switchTheme = (newDataTheme) => setDataTheme(newDataTheme)

  return (
    <ThemeContext.Provider value={{theme: dataTheme, isADarkTheme, switchTheme}}>
      {children}
    </ThemeContext.Provider>
  )
}

/**
 * Custom hook to access the theme context.
 *
 * This hook provides access to the current theme.
 * @returns {TThemeContext}
 */
export const useTheme = () => useContext(ThemeContext);

