import React, { useState, useEffect } from "react";
import axios from "axios";
import { Button, Form, InputText, Modal, Select, Textarea } from "../../UI";
import {encodebody, getDecodedBody} from "../../utils/utils.js";

function Modalworkflow({ clientNr, explorerId, open, onClose }) {
  const [products, setProducts] = useState([]);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [selectedWorkflowName, setSelectedWorkflowName] = useState("No Name Yet");
  const [selectedWorkflowDescription, setSelectedWorkflowDescription] = useState("No Description Yet");
  const [selectedSequence, setSelectedSequence] = useState("1");

  useEffect(() => {
    if(!open) return;
    const fetchData = async () => {
      // first query products for products listbox

      const myProductsPayload = {
        clientNr: clientNr,
        explorerId: explorerId,
      }
      try {
        const productresponse = await axios.post(process.env.REACT_APP_CENTRAL_BACK + "/product/queryall", encodebody(myProductsPayload));
        const productresponseData = getDecodedBody(productresponse.data);
        setProducts(productresponseData);

        if (productresponseData.length > 0) {
          setSelectedProduct(productresponseData[0].productName);
        }

      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, [open]); // Empty dependency array ensures the effect runs only once after the initial render

  const handleNameChange = (event) => {
    setSelectedWorkflowName(event.target.value);
  };

  const handleDescriptionChange = (event) => {
    setSelectedWorkflowDescription(event.target.value)
    }

  const handleSequenceChange = (event) => {
    const inputValue = event.target.value;

    // Check if the input is a valid number
    if (/^\d+$/.test(inputValue) || inputValue === '') {
      // If it's a valid number or an empty string, update the state
      setSelectedSequence(inputValue);
    }
    // If it's not a valid number, you can choose to do nothing or provide feedback to the user
    // For example, show an error message or prevent further action
  };

  const handleSave = async (e) => {
    e.preventDefault();

    // Perform save logic with selectedSource and selectedTarget
    // You can use these values to update your backend or state, as needed
    if (await handleCreateProduct(selectedProduct, selectedWorkflowName,selectedWorkflowDescription, selectedSequence))
    {
      onClose();
    }
  };

  async function handleCreateProduct(productName, workflowName, workflowDescription, sequence) {
    try {
      const mypayload = {
        clientNr: clientNr,
        explorerId: explorerId,
        productName: productName,
        name: workflowName,
        sequence: sequence,
        description: workflowDescription,
        status: "Private",
      };

      const response = await axios.post(process.env.REACT_APP_CENTRAL_BACK + "/workflow/register", encodebody(mypayload));
      const responseData = getDecodedBody(response.data)
      // Check if the response indicates an error
      if (responseData && responseData.error) {
        // Display an alert with the error data
        alert(`Error: ${responseData.error}`);
        return false;
      }

      // setNodesAdded(nodesAdded + 1);
      return true;
    } catch (error) {
      // Handle unexpected errors (e.g., network issues)
      console.error("An unexpected error occurred:", error);
      alert("An unexpected error occurred. Please try again.");
      return false;
    }
  };

  return (
    <Modal open={open} onClose={onClose} maxWidth="60rem">
      <Modal.Title>
        Add New Workflow
      </Modal.Title>
      <Modal.Body>
        <Form onSubmit={handleSave}>
          <Form.Control>
            <Form.Label htmlFor="products">Product</Form.Label>
            <Select
              id="products"
              value={selectedProduct}
              onChange={(e) => setSelectedProduct(e.target.value)}
              options={products.map(({productName}) => ({label: productName, value: productName}))}
              />
          </Form.Control>
          <Form.Control>
            <Form.Label htmlFor="workflowName">Workflow Name</Form.Label>
            <InputText
              id="workflowName"
              value={selectedWorkflowName}
              onChange={handleNameChange}
              />
          </Form.Control>
          <Form.Control>
            <Form.Label htmlFor="sequence">Sequence in product tree</Form.Label>
            <InputText
              id="sequence"
              value={selectedSequence}
              onChange={handleSequenceChange}
            />
          </Form.Control>
          <Form.Control>
            <Form.Label htmlFor="workflowDescription">Description</Form.Label>
            <Textarea
              id="workflowDescription"
              value={selectedWorkflowDescription}
              onChange={handleDescriptionChange}
              rows="5"
            />
          </Form.Control>
          <Form.Row>
            <Button color="secondary" onClick={onClose}>Cancel</Button>
            <Button type="submit">Add Workflow</Button>
          </Form.Row>
        </Form>
      </Modal.Body>
    </Modal>
  );
}


export default Modalworkflow;
