import React, { useState, useEffect } from "react";
import axios from "axios";
import crypto from 'crypto-js';
import ReactJson from 'react-json-view';
import { useTheme } from "../../context/ThemeContext.js";
import { CodeEditor, Form, Icon, Terminal, Textarea, Toolbar } from "../../UI";
import {HeadersGlobalAdd, requestBodyGlobalAdd, addAuthToHeaders, addAuthToRequestBody, parseApiHeaders, getConfiguration, isValidConfiguration, isObject} from "../../utils/api-spec-util.js";
import { encodebody, getDecodedBody } from "../../utils/utils.js";
import HelpCenterIcon from '@mui/icons-material/HelpCenter';
import './apiTerminal.css';

const ApiTerminal = ({ clientNr, explorerId, productName, workflowName, taskId, apiName }) => {
  const [gwoken, setGwoken] = useState('saasasasas');
  const [chatbotKey, setChatbotKey] = useState('chatbot199');
  const [username, setUsername] = useState('Rodolfo Dominguez');
  const [response, setResponse] = useState('');
  const [route, setRoute] = useState('');
  const [api, setApi] = useState([]);
  const [explorer, setExplorer] = useState([]);
  const [requestBody, setRequestBody] = useState({});
  const [reload, setReload] = useState(false);
  const { isADarkTheme } = useTheme();


  function removeProperty(propertyName, object) {
    // create a new object to store the result
    let result = {};
    // loop through the keys of the original object
    for (let key in object) {
      // if the key is not equal to the property name to remove
      if (key !== propertyName) {
        // copy the key-value pair to the result object
        result[key] = object[key];
      }
    }
    // return the result object
    return result;
  }

  const commands = {
    whoami: () => {
  
      return "jackharper";
    },
    cd: (directory) => {
      const myreturn = `changed path to ${directory}`;
      return myreturn;
    },
    run: async () => {
      try {
        const myResponse = await handleSubmit();
        

        let jsonResponse;
        if (typeof myResponse === 'string') {
          jsonResponse = { data: myResponse };
        } else {
          jsonResponse = myResponse;
        }

        return (
          <ReactJson src={jsonResponse} theme="apathy" name={null} collapsed={1} />
        );
      } catch (error) {
        console.error('Error during API execution:', error);
        return <div className="terminal__message">{'An error occurred during API execution'}</div>;
      }
    },
  };

  const handleSave = async () => {
    try {
      const user = JSON.parse(localStorage.getItem("user"));
      const endpoint = `${process.env.REACT_APP_CENTRAL_BACK}/api/registercustom`;

      const myCustomPayload = {
        taskId: taskId,
        ...api,
        requestBody: requestBody,
        clientNr: clientNr,
        explorerId: explorerId,
        userClientNr: user.clientNr,
        urlRoute: route
      };

      const registerCustomPayload = removeProperty("_id", myCustomPayload);

      const response = await axios.post(endpoint, encodebody(registerCustomPayload));
      alert("Custom API values saved!");
      setReload(true);
    } catch (error) {
      alert("Error during save operation: " + (error.response ? JSON.stringify(getDecodedBody(error.response.data)) : error.message));
    }
  };

  const handleRestoreDefault = async () => {
    try {
      const user = JSON.parse(localStorage.getItem("user"));
      const endpoint = `${process.env.REACT_APP_CENTRAL_BACK}/api/deletecustom`;

      const query = {
        userClientNr: user.clientNr,
        explorerId: explorerId,
        taskId: taskId,
        name: apiName
      };

      const response = await axios.post(endpoint, encodebody(query));
      alert("Default API values restored!");
      setReload(true);
    } catch (error) {
      alert((error.response ? JSON.stringify(getDecodedBody(error.response.data)) : error.message));
    }
  };


  const handleRequestBodyChange = (value, event) => {
    
    
    let parsedValue;
  
    // Check if the value is an empty string and set it to an empty object
    if (value === '') {
      parsedValue = {};
    } else {
      try {
        // Attempt to parse the value as JSON
        parsedValue = JSON.parse(value);
      } catch (error) {
        console.error("Invalid JSON:", error);
        // If the value is not valid JSON, you might want to handle this case, 
        // e.g., by not calling setApiData, showing an error message, etc.
        return; // Exit the function if the JSON is invalid
      }
    }
    // Update the state with the parsed value, which is now guaranteed to be an object
    setRequestBody(parsedValue);
  };

  const handleRouteChange = (value) => {
    setRoute(value);
  };

  useEffect(() => {
    if (reload) {
      setReload(false); 
     }
    fetchApi();
  }, [ reload, clientNr, explorerId, apiName]);

  const fetchApi = async () => {
    var user = JSON.parse(localStorage.getItem("user"));

    try {
      const myApibody = {
        clientNr: clientNr,
        explorerId: explorerId,
        taskId:taskId,
        userClientNr: user.clientNr,
        name: apiName,
        custom: true
      };
      const response = await axios.post(process.env.REACT_APP_CENTRAL_BACK + "/api/query", encodebody(myApibody));
      var myApi = getDecodedBody(response.data);
      

      const myLinkParamPayload = {
        clientNr: clientNr,
        explorerId: explorerId,
        workflowName: workflowName,
        taskId: taskId,
        chatbotKey: user.chatbotKey,
        email: user.email,
        baseUrl: myApi.resourcePath ? myApi.resourcePath : ""
      };
    

      var activeLinks = false;
      try
      {
      const myLinkParamResponse = await axios.post(process.env.REACT_APP_CENTRAL_BACK + "/link/querylinkparameters", encodebody(myLinkParamPayload));
      var myParams = getDecodedBody(myLinkParamResponse.data);
        if (myParams.activeLinks) {
          setRoute(myParams.path);
          activeLinks = true;
        } else {
          console
          setRoute(myApi.urlRoute);
          activeLinks = false;
        }
      }
      catch(error)
      {
        setRoute(myApi.urlRoute);
        activeLinks = false
      }

    
      const myExplorerbody = {
        clientNr: clientNr,
        explorerId: explorerId
      };
     
      const Eresponse = await axios.post(process.env.REACT_APP_CENTRAL_BACK + "/explorer/query", encodebody(myExplorerbody));
      const myExplorer = getDecodedBody(Eresponse.data);
      setExplorer(myExplorer);
     

      if (myApi.requestBody) {
      
        const yamlObject = await getConfiguration(myExplorer, myApi.thirdparty);

        var initialRequestBody;
        if (activeLinks) {
          initialRequestBody = myParams.requestBody ;
          myApi.requestBody = myParams.requestBody;
        } else {
          initialRequestBody = myApi.requestBody ;
        }

        setApi(myApi);

        const myRequestBodyWithGlobals = requestBodyGlobalAdd(initialRequestBody, yamlObject);
      
        setRequestBody(myRequestBodyWithGlobals);
      }
      setApi(myApi);

    } catch (error) {
      console.log(error);
    }
  };

  const handleSubmit = async () => {
    try {
      setResponse('');

      if (!isValidConfiguration(explorer)) {
        alert("The GWOCU Studio configuration file is not a valid yaml file.");
        return;
      }
      const yamlObject = await getConfiguration(explorer, api.thirdparty);
    
      const apiHeaders = parseApiHeaders(api);
    

      const myheadersWithGlobals = HeadersGlobalAdd(apiHeaders, yamlObject);
      const finalHeaders = addAuthToHeaders(myheadersWithGlobals, yamlObject);
     
      const finalRequestBody = addAuthToRequestBody(requestBody, yamlObject, crypto);

      finalHeaders["destination"] = route;


      const allowedMethodsForBody = ["POST", "PUT", "PATCH"];
      const fetchOptions = {
        method: api.method,
        headers: {
          ...finalHeaders,
        },
      };

      if (allowedMethodsForBody.includes(api.method.toUpperCase())) {
        fetchOptions.body = JSON.stringify(finalRequestBody);
      }

    
      const fetchResponse = await fetch(process.env.REACT_APP_CENTRAL_BACK + "/relay", fetchOptions);

      const responseData = await fetchResponse.json();
      setResponse(JSON.stringify(responseData, null, 2));

      const resultWithStatus = {
        status: fetchResponse.status,
        resultBody: responseData
      };

      const user = JSON.parse(localStorage.getItem("user"));
      const endpoint = `${process.env.REACT_APP_CENTRAL_BACK}/api/registerapiresult`;

      const myresultPayload = {
        result: { ...resultWithStatus },
        clientNr: clientNr,
        explorerId: explorerId,
        name: apiName,
        email: user.email,
        chatbotKey: user.chatbotKey,
      };
      try {
        await axios.post(endpoint, encodebody(myresultPayload));
      } catch (error) {
        console.log("An error occured when saving result", error);
      }

      return resultWithStatus;

    } catch (error) {
      console.error('Error during API execution:', error);
      setResponse(JSON.stringify(error, null, 2));
      throw error;
    }
  };

  return (
    <>
      <header className="section-right__toolbar-container">
        <Toolbar>
          <Toolbar.Button onClick={handleSave}>Save</Toolbar.Button>
          <Toolbar.Button onClick={handleRestoreDefault}>Restore Default</Toolbar.Button>
          <Toolbar.Item>
            <Icon href="https://wiki.gwocu.com/en/GWOCU-Studio/curl-execution">
              <HelpCenterIcon />
            </Icon>
          </Toolbar.Item>
        </Toolbar>
      </header>
      <div className="section-right__content">
        <Form id="form" className="api-terminal__curl-form" onSubmit={handleSubmit}>
          <Form.Row>
            <Form.Label>
              <pre>1  // API EXPLORER. NOTE, FIELDS ARE EDITABLE!!</pre>
              <pre>2  //</pre>
              <pre>3  // API Name: {api.name}.</pre>
              <pre>4  //</pre>
              <pre>5  curl -X {api.method}</pre>
            </Form.Label>
          </Form.Row>
          <Form.Row>
            <Textarea
              title="cURL"
              name="route"
              value={route}
              onChange={(e) => handleRouteChange(e.target.value)}
            />
          </Form.Row>
          <Form.Row>
            {api.headers && api.headers.map((header) => `-H "${header}"`).join(' ')} -d {' '}
          </Form.Row>
          <Form.Row>
            <CodeEditor
              id="json-editor"
              defaultLanguage="json"
              value={api.requestBody}
              height="300px"
              onChange={handleRequestBodyChange}
              darkMode={isADarkTheme}
            />
          </Form.Row>
        </Form>
        <div className="section-right__terminal-container section-right__terminal-container--api-terminal">
          <Terminal
            commands={commands}
            welcomeMessage={
              <div>
                Welcome to the API execution terminal.
                <br />
                Please type in "run" to submit the cURL.
                <br />
              </div>
            }
          />
        </div>
      </div>
    </>
  );
};

function isJSON(str) {
  try {
    JSON.parse(str);
    return true;
  } catch (e) {
    return false;
  }
}
export default ApiTerminal;
