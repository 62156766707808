import React, { useState, useEffect } from 'react';
import axios from "axios";
import { Button, Icon, Form, InputText, Modal, Textarea, Tooltip } from '../../UI';
import {encodebody, getDecodedBody} from "../../utils/utils.js";
import tooltips from '../../tooltips/tooltips';
import HelpCenterIcon from '@mui/icons-material/HelpCenter';
import "./thirdparties.css";

const Thirdparties = ({clientNr, explorerId, open, onClose}) => {
  const [records, setRecords] = useState([]);
  const [selectedRecord, setSelectedRecord] = useState(null);
  const [formData, setFormData] = useState({
    clientNr: clientNr,
    name: '',
    description: '',
    yaml: '',
  });

  useEffect(() => {
    if(!open) return;
    // Fetch all records when the component mounts
    fetchRecords();
  }, [open]);

  const fetchRecords = async () => {
    const payload =
    {
        clientNr:clientNr
    }
    try {
      const response = await axios.post(process.env.REACT_APP_CENTRAL_BACK + '/thirdparties/queryall', encodebody(payload));
      setRecords(getDecodedBody(response.data));
    } catch (error) {
      console.error('Error fetching records:', error);
    }
  };

  const handleSelectRecord = (record) => {
    setSelectedRecord(record);
    setFormData(record);
  };

  const handleDeleteRecord = async () => {
    const payload = {
        clientNr: clientNr,
        name: selectedRecord.name
    }
    try {
      await axios.post(process.env.REACT_APP_CENTRAL_BACK + '/thirdparties/delete', encodebody(payload));
      fetchRecords();
      setSelectedRecord(null);
      setFormData({
        clientNr: clientNr,
        name: '',
        description: '',
        yaml: '',
      });
    } catch (error) {
      console.error('Error deleting record:', error);
    }
  };

  const handleUpdateRecord = async () => {
    try {
      await axios.post(process.env.REACT_APP_CENTRAL_BACK + '/thirdparties/update', encodebody(formData));
      fetchRecords();
      setSelectedRecord(null);
      setFormData({
        clientNr: clientNr,
        name: '',
        description: '',
        yaml: '',
      });
    } catch (error) {
      alert('Error updating third party. Please check your fields');
    }
  };

  const handleAddRecord = async () => {
    try {
      await axios.post(process.env.REACT_APP_CENTRAL_BACK + '/thirdparties/register', encodebody(formData));
      fetchRecords();
      setFormData({
        clientNr: clientNr,
        name: '',
        description: '',
        yaml: '',
      });
    } catch (error) {
      alert('Error adding third party. Please check your fileds');
    }
  };

  return (
    <Modal open={open} onClose={onClose} maxWidth="80rem">
      <Modal.Title>API Actions</Modal.Title>
      <Modal.Body>
        <div className="third-parties__table-container">
          <table>
            <thead>
              <tr>
                <th>Main Client</th>
                <th>Api Action Name</th>
                <th>Description</th>
              </tr>
            </thead>
            <tbody>
              {records.map((record) => (
                <tr key={record.clientNr} onClick={() => handleSelectRecord(record)}>
                  <td>{record.clientNr}</td>
                  <td>{record.name}</td>
                  <td>{record.description}</td>
                </tr>
              ))}
            </tbody>
          </table>
          <Tooltip tooltip={tooltips.thirdparties} placement="right" trigger="click" interactive={true}>
            <Icon interactive={true} className="table__icon--help">
              <HelpCenterIcon />
            </Icon>
          </Tooltip>
        </div>
        <Form>
          <InputText
            type="hidden"
            className="third-parties__form-input-client"
            value={formData.clientNr}
            onChange={(e) => setFormData({ ...formData, clientNr: e.target.value })}
          />
          <Form.Control>
            <Form.Label>
              Api Action Name:
            </Form.Label>
            <InputText
              value={formData.name}
              onChange={(e) => setFormData({ ...formData, name: e.target.value })}
            />
          </Form.Control>
          <Form.Control>
            <Form.Label>Description:</Form.Label>
            <InputText
              value={formData.description}
              onChange={(e) => setFormData({ ...formData, description: e.target.value })}
            />
          </Form.Control>
          <Form.Control>
            <Form.Label>YAML:</Form.Label>
            <Textarea
              value={formData.yaml}
              onChange={(e) => setFormData({ ...formData, yaml: e.target.value })}
              Controls= "5"
            />
          </Form.Control>
          <Form.Row>
          {selectedRecord ? (
            <>
              <Button color="secondary" onClick={onClose}>Cancel</Button>
              <Button color="danger" onClick={handleDeleteRecord}>Delete</Button>
              <Button onClick={handleUpdateRecord}>Update</Button>
            </>
          ) : (
            <>
              <Button color="secondary" onClick={onClose}>Cancel</Button>
              <Button onClick={handleAddRecord}>Add Action</Button>
            </>
          )}
          </Form.Row>
        </Form>
      </Modal.Body>
    </Modal>
  );
};

export default Thirdparties;
