import { forwardRef } from "react";
import styled, { css } from "styled-components";

const sizeStyles = ({ $size = "md", $rounded }) => {
  switch ($size) {
    case "xs":
      return css`
        font-size: 1.6rem;
        padding: ${$rounded ? "0.4rem" : "0.2rem 0.4rem"};
      `;
    case "sm":
      return css`
        font-size: 1.6rem;
        padding: ${$rounded ? "0.8rem" : "0.4rem 0.8rem"};
        `;
      /* font-size: 1.8rem;
      padding: 0.2rem 0.4rem; */
    case "md":
      return css`
        font-size: 1.6rem;
        padding: ${$rounded ? "1.2rem" : "1.2rem 1.6rem"};
      `;
    case "lg":
      return css`
        font-size: 2rem;
        padding: ${$rounded ? "1.2rem" : "1.2rem 2.4rem"};
      `;
    default:
      return css``;
  }
};

export const StyledButton = styled.button`
  border: none;
  font-family: inherit;
  font-size: inherit;
  border-radius: ${({ $rounded }) => ($rounded ? "50%" : "0.5em")};
  aspect-ratio: ${({ $rounded }) => ($rounded ? "1" : "revert")};
  font-weight: 700;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-grow: ${({ $rounded }) => ($rounded ? "0" : "revert")};
  flex-shrink: ${({ $rounded }) => ($rounded ? "0" : "revert")};
  align-self: ${({ $rounded }) => ($rounded ? "center" : "revert")};
  width: ${({ $fullWidth }) => ($fullWidth ? "100%" : "auto")};
  gap: 0.5em;
  transition: background-color var(--transition-duration-normal, 250ms) ease-in-out;
  ${sizeStyles}

  /* &.button svg {
    display: block;
    height: 1em;
    width: 1em;
    font-size: inherit;
    pointer-events: none;
  } */

  ${({ color }) => {
    return css`
      background-color: var(--btn-${color}-bg-clr);
      color: var(--btn-${color}-txt-clr);

      &:hover,
      &:active,
      &:focus-visible {
        background-color: var(--btn-${color}-bg-clr-hover);
        color: var(--btn-${color}-txt-clr-hover);
      }

      &:focus-visible {
        outline: 4px solid var(--btn-${color}-outline-clr);
        outline-offset: 4px;
      }

      &:disabled {
        background-color: var(--btn-${color}-bg-clr-disabled);
        color: var(--btn-${color}-txt-clr-disabled);
        cursor: not-allowed;
      }

      & > svg {
        fill: var(--btn-${color}-icon-clr);
        color: var(--btn-${color}-icon-clr);
      }

      &:hover > svg,
      &:active > svg,
      &:focus-visible > svg {
        color: var(--clr-icon-on-${color}-hover);
      }

      &:disabled > svg {
        color: var(--btn-${color}-icon-clr-disabled);
      }
    `;
  }
}`;

/**
 * @typedef {Object} ButtonProps
 * @property {React.ReactNode} children - The content inside the button.
 * @property {string} [className=undefined] - Adds a custom className for the button. Default value: `undefined`.
 * @property {"primary" | "secondary" | "danger"} [color="primary"] - The color of the button. Default value: `"primary"`.
 * @property {boolean} [disabled=false] - If `true`, the button is disabled. Default value: `false`.
 * @property {boolean} [fullWidth=false] - If `true`, the button will take up the full width of its container. Default value: `false`.
 * @property {function} [onClick=undefined] - The `click` event handler. Default value: `undefined`.
 * @property {boolean} [rounded=false] - If `true`, the button has fully rounded corners. Default value: `false`.
 * @property {"xs" | "sm" | "md" | "lg"} [size="md"] - The size of the button. Default value: `"md"`.
 * @property {string} [title=undefined] - The native tooltip text for the button. Default value: `undefined`.
 * @property {"button" | "submit" | "reset"} [type="button"] - The type of the button. Default value: `"button"`.
 */

/**
 * Gwocu's custom button component.
 *
 * @component
 * @param {ButtonProps} props - The properties for the button component.
 * @param {React.Ref} ref - The ref passed to the button element.
 * @returns {JSX.Element} The rendered button component.
 *
 * @example
 * <Button color="danger" size="lg" onClick={handleDelete}>Delete</Button>
 */
export const Button = forwardRef((
  /** @type {ButtonProps} */ {
  children,
  title = undefined,
  size = "md",
  color = "primary",
  disabled = false,
  fullWidth = false,
  onClick,
  className,
  rounded = false,
  type = "button",
}, /** @type {React.RefAttributes<HTMLInputElement>} */ ref) => {

  return (
    <StyledButton
      color={color}
      disabled={disabled}
      $fullWidth={fullWidth}
      onClick={onClick}
      className={className}
      type={type}
      title={title}
      $rounded={rounded}
      $size={size}
      ref={ref}
    >
      {children}
    </StyledButton>
  );
});
