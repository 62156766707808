import { forwardRef } from "react";
import styled from "styled-components";

const StyledRadioButton = styled.label`
  display: flex;
  align-items: center;
  gap: 0.25em;

  & input[type="radio"] {
    -webkit-appearance: none;
    appearance: none;
    background-color: var(--radio-bg-clr);
    margin: 0;
    font: inherit;
    width: 1.5em;
    height: 1.5em;
    border-radius: 50%;
    border: 0.15em solid var(--radio-border-clr);
    position: relative;
    transition:
      background-color var(--transition-duration-normal, 250ms) ease-in-out,
      border-color var(--transition-duration-normal, 250ms) ease-in-out;
  }

  & input[type="radio"]::before {
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    background-color: var(--radio-checkmark-clr);
    width: 0.75em;
    height: 0.75em;
    border-radius: 50%;
    transform: translate(-50%, -50%) scale(0);
    transition: transform var(--transition-duration-fast, 120ms) ease-in-out;
  }

  &:hover input[type="radio"],
  &:hover input[type="radio"]:checked,
  & input[type="radio"]:checked {
    background-color: var(--radio-bg-clr-hover);
    border-color: var(--radio-border-clr-hover);
  }

  & input[type="radio"]:checked {
    border-color: var(--radio-border-clr-selected);
  }

  & input[type="radio"]:checked::before {
    transform: translate(-50%, -50%) scale(1);
  }

  & input[type="radio"]:focus-visible {
    outline: none;
  }

  &.disabled {
    color: var(--input-txt-clr-disabled);
  }

  &.disabled input[type="radio"],
  &.disabled:hover input[type="radio"] {
    border-color: var(--radio-border-clr-disabled);
    background-color: var(--radio-bg-clr-disabled);
    cursor: not-allowed;
  }

  &.disabled input[type="radio"]:checked::before {
    background-color: var(--radio-checkmark-clr-disabled);
  }

  &.invalid {
    color: var(--input-txt-clr-invalid);
  }

  &.invalid input[type="radio"],
  &.invalid:hover input[type="radio"] {
    background-color: var(--radio-bg-clr-invalid);
    border-color: var(--radio-border-clr-invalid);
  }

  &.invalid input[type="radio"]:checked::before {
    background-color: var(--radio-checkmark-clr-invalid);
  }
`;

/**
* @typedef {Object} RadioButtonProps
 * @property {boolean} [props.checked=false] - If `true`, the radio button is selected. Default value: `false`.
 * @property {React.ReactNode} props.children - The label beside the radio button.
 * @property {string} [props.className=undefined] - Adds a custom className for the radio button. Default value: `undefined`.
 * @property {boolean} [props.disabled=false] - If `true`, the radio button is disabled. Default value: `false`.
 * @property {boolean} [props.error=false] - If `true`, the styling changes to indicate the radio button is invalid. Default value: `false`.
 * @property {string} [props.id=undefined] - The id of the radio button. Default value: `undefined`.
 * @property {string} [props.name=undefined] - The name of the radio button. Default value: `undefined`.
 * @property {function} [props.onChange=undefined] - The `change` event handler. Default value: `undefined`.
 * @property {string} [props.value=undefined] - The value of the radio button element. Default value: `undefined`.
 * @property {boolean} [props.required=false] - If `true`, checking the radio button is required. Default value: `false`.
 */

/**
 * Gwocu's radio button component
 *
 * This is a customized native radio button element. It supports event handling and various states like checked, disabled, and error.
 * @component
 * @param {RadioButtonProps} props - The props for the radio button component.
 * @param {React.Ref} ref - The ref passed to the radio button element.
 * @returns {JSX.Element} The rendered radio button component.
 *
 * @example
 * <Form.Control>
 *   <Form.Label>Choose an LLM model</Form.Label>
 *   <Form.Col>
 *     <RadioButton name="llm" id="radio-llama" value="llama">LLaMA</RadioButton>
 *     <RadioButton name="llm" id="radio-gpt" value="gpt">GPT</RadioButton>
 *     <RadioButton name="llm" id="radio-lamda" value="lamda">LaMDA</RadioButton>
 *   </Form.Col>
 * </Form.Control>
 */
export const RadioButton = forwardRef(
  (
    /** @type { RadioButtonProps } */ props,
    /** @type { React.RefAttributes<HTMLInputElement> } */ ref
  ) => {
    const classes = `radiobutton-container ${
      props.disabled ? "disabled" : ""
    } ${props.error ? "invalid" : ""}`;

    return (
      <StyledRadioButton className={classes} htmlFor={props.id}>
        <input
          ref={ref}
          id={props.id}
          type="radio"
          checked={props.checked}
          className={props.className}
          disabled={props.disabled}
          name={props.name}
          onChange={props.onChange}
          required={props.required}
          value={props.value}
        />
        {props.children && <span>{props.children}</span>}
      </StyledRadioButton>
    );
  }
);
